import { useState } from 'react';

const useInputFile = (
  initialState: string[],
  setFail: (value: string[]) => void,
  setSuccess: (value: string[]) => void,
  setTotalNum: (value: number) => void
): [string[], any, () => void] => {
  const [fileState, setFileState] = useState(initialState);

  const handleSelectFile = (e: any) => {
    setFail([]);
    setSuccess([]);
    setTotalNum(0);

    const arr: any = [];
    const value = e.currentTarget.files;

    for (const file of value) {
      arr.push(file);
    }
    setFileState(arr);
  };

  const resetState = () => {
    setFileState([]);
  };

  return [fileState, handleSelectFile, resetState];
};

export default useInputFile;

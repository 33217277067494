import {ChangeEvent, FormEvent, useEffect, useState } from 'react';
import Button from '../../Common/Button/Button';
import FormLayout from '../../Common/FormLayout/FormLayout';
import Input from '../../Common/Input/Input';
import { LoadingModal } from '../LoadingModal/LoadingModal';
import { updateProject } from '../../../services/axiosPrivate';
import Select from '../../Common/Select/Select';
import styles from './projectUpdateModal.module.scss';

interface IProps {
  isShowModal: boolean;
  setIsShowModal: (value: boolean) => void;
  editData: any;
  projectTypes: any;
}

const ProjectUpdateModal = ({ isShowModal, setIsShowModal, editData, projectTypes }: IProps) => {
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState(editData);
  const ProjectStatus = {
    1: 'Built',
    2: 'Unbuilt',
  } as const;
  const [typeOptions] = useState(projectTypes);
  const [selectedType, setSelectedType] = useState(values.type.name);

  useEffect(() => {
    setSelectedType(values.type.id);
  }, [values.type.id]);

  const handleSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setSelectedType(e.target.value);
    handleChange(e);
  };

  const handleChange = (e: ChangeEvent<any>) => {
    const { id, value, files } = e.target;
    setValues((prevValues: any) => {
      if (files && files.length > 0) {
        // 파일 input 처리
        return {
          ...prevValues,
          [id]: files[0]
        };
      } else {
        // 일반 input 처리
        return {
          ...prevValues,
          [id]: typeof value === "object" ? JSON.stringify(value) : value
        };
      }
    });
  };

  const handleCancelBtn = () => {
    setIsShowModal(!isShowModal);
  };

  const handleClickAdd = async (e: FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    try {
      setLoading(true);

      setValues((value: any) => {
        return {
          ...value,
        };
      });

      const formData = new FormData();
      Object.entries(values).forEach(([key, value]) => {
        if (value instanceof File) {
          formData.append(key, value);
        } else if (key === 'maker' || key === 'type') {
          formData.append(key, JSON.stringify(value));
        } else {
          // @ts-ignore
          formData.append(key, value);
        }
      });

      await updateProject(values.id, formData);
    } catch (error: any) {
      console.error(error.message);
    } finally {
      setLoading(false);
      setIsShowModal(!isShowModal);
      window.location.href = '/project';
    }
  }

  return (
      <article className={styles.whiteListWrap}>
        <form className={styles.scrollableForm}>
          <FormLayout id='name' label='이름'>
            <Input value={values.name} type='text' id='name' changeValue={handleChange} />
          </FormLayout>
          <FormLayout id='description' label='설명'>
            <Input value={values.description} type='text' id='description' changeValue={handleChange} />
          </FormLayout>
          <FormLayout id='status' label='상태'>
            <Select
                value={values.status.toString()}
                id='status'
                onChange={handleChange}
                options={Object.entries(ProjectStatus).map(([key, value]) => ({ value: key, label: value }))}
            />
          </FormLayout>
          <FormLayout id='firstFloor' label='지상'>
            <Input value={values.firstFloor} type='text' id='firstFloor' changeValue={handleChange} />
          </FormLayout>
          <FormLayout id='basement' label='지하'>
            <Input value={values.basement} type='text' id='basement' changeValue={handleChange} />
          </FormLayout>
          <FormLayout id='year' label='연도'>
            <Input value={values.year} type='number' id='year' changeValue={handleChange} />
          </FormLayout>
          <FormLayout id='location' label='위치'>
            <Input value={values.location} type='text' id='location' changeValue={handleChange} />
          </FormLayout>
          <FormLayout id='size' label='크기'>
            <Input value={values.size} type='text' id='size' changeValue={handleChange} />
          </FormLayout>
          <FormLayout id='perspectiveImage' label='조감도'>
            <Input type='file' id='perspectiveImage' changeValue={handleChange} />
          </FormLayout>
          <FormLayout id='siteplanImage' label='배치도'>
            <Input type='file' id='siteplanImage' changeValue={handleChange} />
          </FormLayout>
          <FormLayout id='typicalFloorPlanImage' label='평면도'>
            <Input type='file' id='typicalFloorPlanImage' changeValue={handleChange} />
          </FormLayout>
          <FormLayout id='elevationImage' label='입면도'>
            <Input type='file' id='elevationImage' changeValue={handleChange} />
          </FormLayout>
          <FormLayout id='sideElevationImage' label='측면도'>
            <Input type='file' id='sideElevationImage' changeValue={handleChange} />
          </FormLayout>
          <FormLayout id='lSectionImage' label='L단면도'>
            <Input type='file' id='lSectionImage' changeValue={handleChange} />
          </FormLayout>
          <FormLayout id='sSectionImage' label='S단면도'>
            <Input type='file' id='sSectionImage' changeValue={handleChange} />
          </FormLayout>
          <FormLayout id='maker' label='제작자'>
            <Input value={values.maker.nickname} type='text' id='maker' changeValue={handleChange} />
          </FormLayout>
          <FormLayout id="type" label="타입">
            <Select
                id="type"
                value={selectedType}
                onChange={handleSelectChange}
                options={typeOptions}
            />
          </FormLayout>
          {values.type === '5' && (
              <FormLayout id='otherProjectType' label='타입 이름 입력'>
                <Input type='text' id='otherProjectType' changeValue={handleChange} />
              </FormLayout>
          )}

          <div className={styles.btnWrap}>
            <Button isSubmit styleName='addBtn' clickBtn={handleClickAdd} text='수정'/>
            <Button styleName='cancelBtn' clickBtn={handleCancelBtn} text='취소'/>
          </div>
        </form>
        {loading ? <LoadingModal/> : null}
      </article>
  );
};

export default ProjectUpdateModal;

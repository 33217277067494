import { FormEvent, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import * as xlsx from 'xlsx';

import { batchMintApi } from '../../../services/nftContract';
import { getHasRole } from '../../../services/roleManager';
import { s3UploadApi } from '../../../services/axiosPrivate';
import useInputFile from '../../../hooks/useInputFile';
import useOnClickOutside from '../../../hooks/useOnClickOutside';

import Button from '../../Common/Button/Button';
import FormLayout from '../../Common/FormLayout/FormLayout';
import Input from '../../Common/Input/Input';

import styles from './batchMintModal.module.scss';

interface IProps {
  isShowModal: boolean;
  setIsShowModal: (value: boolean) => void;
}
const BatchMintModal = ({ isShowModal, setIsShowModal }: IProps) => {
  const [islandNum, setIsLandNum] = useState('');
  const [category, setCategory] = useState('');
  const [usageArr, setUsageArr] = useState<string[]>([]);
  const [scaleArr, setScaleArr] = useState([]);
  const [formArr, setFormArr] = useState([]);
  const [amountArr, setAmountArr] = useState([]);
  const [makerAddressArr, setMakerAddressArr] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [failArr, setFailArr] = useState<string[]>([]);
  const [successArr, setSuccessArr] = useState<string[]>([]);
  const [totalNum, setTotalNum] = useState(0);
  const [metaData, setMetaData] = useState<any>({});
  const [resourceFile, setResourceFile] = useInputFile([], setFailArr, setSuccessArr, setTotalNum);
  const [loading, setLoading] = useState(false);

  const ref = useRef(null);

  useOnClickOutside(ref, () => {
    if (isShowModal) {
      setIsShowModal(false);
    }
  });

  const handleCancelBtn = () => {
    setIsShowModal(false);
  };

  const readExcel = (e: FormEvent<HTMLInputElement>) => {
    setFailArr([]);
    setSuccessArr([]);
    setTotalNum(0);

    if (!e.currentTarget.files) return;
    const input = e.currentTarget.files;

    const reader = new FileReader();
    if (!input) return;

    reader.onload = () => {
      const usgaeRegex = /[^RCIO]/;
      const scaleRegex = /[^123]/;
      const formRegex = /[^ABCDE]/;

      const data = reader.result;
      const workBook = xlsx.read(data, { type: 'binary' });
      workBook.SheetNames.forEach((sheetName) => {
        const jsonData: any = xlsx.utils.sheet_to_json(workBook.Sheets[sheetName], {
          header: 0, // 첫 번째 행을 헤더(열의 이름)로 간주하지 않고 데이터로 처리
          blankrows: false, // 빈 행을 데이터로 처리하지 않음
          range: 4, // 네 번째 행부터 데이터 읽어옴
        });

        setMetaData(jsonData);

        if (jsonData[0].category === 'LAND') {
          const usage = jsonData.reduce((acc: any, curVal: any) => [...acc, curVal.usage], []);
          const scale = jsonData.reduce((acc: any, curVal: any) => [...acc, curVal.scale], []);
          const form = jsonData.reduce((acc: any, curVal: any) => [...acc, curVal.form], []);

          const usageCheck = usage.map((item: any) => {
            return usgaeRegex.test(item);
          });

          const formCheck = form.map((item: any) => {
            return formRegex.test(item);
          });

          const scaleCheck = scale.map((item: any) => {
            return scaleRegex.test(item);
          });

          if (usageCheck.includes(true)) {
            setErrorMsg('usage에 잘못된 값 있음');
          } else if (formCheck.includes(true)) {
            setErrorMsg('form에 잘못된 값 있음');
          } else if (scaleCheck.includes(true)) {
            setErrorMsg('scale에 잘못된 값 있음');
          } else {
            setErrorMsg('');
            setUsageArr(usage);
            setScaleArr(scale);
            setFormArr(form);
          }
        } else {
          const usage = jsonData.reduce((acc: any, curVal: any) => [...acc, curVal.usage], []);
          const scale = jsonData.reduce((acc: any, curVal: any) => [...acc, curVal.scale], []);
          const form = jsonData.reduce((acc: any, curVal: any) => [...acc, curVal.form], []);

          const usageCheck = usage.map((item: any) => {
            return usgaeRegex.test(item);
          });

          const formCheck = form.map((item: any) => {
            return formRegex.test(item);
          });

          const scaleCheck = scale.map((item: any) => {
            return scaleRegex.test(item);
          });

          if (usageCheck.includes(true)) {
            setErrorMsg('usage에 잘못된 값 있음');
          } else if (formCheck.includes(true)) {
            setErrorMsg('form에 잘못된 값 있음');
          } else if (scaleCheck.includes(true)) {
            setErrorMsg('scale에 잘못된 값 있음');
          } else {
            setErrorMsg('');
            setUsageArr(usage);
            setScaleArr(scale);
            setFormArr(form);
          }
        }

        const amount = jsonData.reduce((acc: any, curVal: any) => [...acc, curVal.amount], []);

        setAmountArr(amount);
        setIsLandNum(jsonData[0].island);
        setMakerAddressArr(jsonData[0].maker_address);
        setCategory(jsonData[0].category);
      });
    };

    reader.readAsBinaryString(input[0]);
  };

  const batchMint = async () => {
    try {
      const res = await batchMintApi(category, {
        island: islandNum,
        usage: usageArr,
        scale: scaleArr,
        form: formArr,
        amount: amountArr,
        makerAddress: makerAddressArr,
      });
      return res;
    } catch (error: any) {
      setLoading(false);
      toast.error(`민팅 실패 ${error.message}`);
      throw new Error(error.message);
    }
  };

  const handleClickMint = async (e: FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setTotalNum(0);
    setFailArr([]);
    setSuccessArr([]);

    if (resourceFile.length >= 3 && usageArr && amountArr && makerAddressArr && !errorMsg) {
      try {
        const res = await getHasRole();
        if (res) {
          toast.success('민팅 권한 확인');
          setLoading(true);
          const nftID = await batchMint();

          if (nftID.length > 0) {
            toast.success('민팅 성공');
            const rows = metaData.map((items: any, index: number) => {
              return {
                ...items,
                ID: nftID[index],
              };
            });
            const worksheet = xlsx.utils.json_to_sheet(rows);
            const workbook = xlsx.utils.book_new();
            xlsx.utils.book_append_sheet(workbook, worksheet, 'Dates');

            xlsx.writeFile(workbook, `${category}_NFT_MINT_SUCCESS.xlsx`);

            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < metaData.length; i++) {
              let data;
              if (metaData[i].category === 'LAND') {
                data = {
                  name: metaData[i].name,
                  description: metaData[i].description,
                  island: Number(metaData[i].island),
                  usage: metaData[i].usage,
                  scale: metaData[i].scale,
                  form: metaData[i].form,
                  direction: metaData[i].direction,
                  district: metaData[i].district,
                  centroid_x: Number(metaData[i].centroid_x),
                  centroid_y: Number(metaData[i].centroid_y),
                };
              } else {
                data = {
                  name: metaData[i].name,
                  description: metaData[i].description,
                  island: metaData[i].island,
                  usage: metaData[i].usage,
                  scale: metaData[i].scale,
                  form: metaData[i].form,
                };
              }

              const resourceFileArr: any = resourceFile.filter((file: any) => {
                return metaData[i].name === file.name.split('.')[0].split(/_image|_thumb|_ref_file1|_ref_file2/)[0];
              });

              const formdata: any = new FormData();

              resourceFileArr.map((item: any) => {
                if (item.name.includes('thumb')) {
                  formdata.append('thumb', item);
                }
                if (item.name.includes('image')) {
                  formdata.append('image', item);
                }
                if (item.name.includes('ref_file1')) {
                  formdata.append('refFile1', item);
                }
                if (item.name.includes('ref_file2')) {
                  formdata.append('refFile2', item);
                }
              });

              formdata.append('id', nftID[i]);
              formdata.append('category', metaData[i].category);
              formdata.append('metadata', JSON.stringify(data));

              try {
                // eslint-disable-next-line no-await-in-loop
                const uploadRes = await s3UploadApi(formdata);

                if (uploadRes) {
                  setLoading(false);
                  setSuccessArr((prev) => [...prev, `${nftID[i]} 업로드 성공`]);
                  setTotalNum((prev) => prev + 1);
                }
              } catch (error: any) {
                setLoading(false);
                setFailArr((prev) => [...prev, `${nftID[i]} 업로드 실패`]);
                setTotalNum((prev) => prev + 1);
              } finally {
                setIsLandNum('');
                setCategory('');
                setUsageArr([]);
                setScaleArr([]);
                setFormArr([]);
                setAmountArr([]);
                setMakerAddressArr('');
              }
            }
          } else {
            setLoading(false);
            toast.error('민팅 실패');
          }
        } else {
          setLoading(false);
          alert('민팅권한이 없습니다.');
        }
      } catch (error: any) {
        setLoading(false);
        alert('다시 시도해주세요');
      } finally {
        setIsLandNum('');
        setCategory('');
        setUsageArr([]);
        setScaleArr([]);
        setFormArr([]);
        setAmountArr([]);
        setMakerAddressArr('');
      }
    } else {
      alert('값을 확인해주세요');
    }
  };

  return (
    <article className={styles.batchMintWrap} ref={ref}>
      <form>
        <FormLayout id='upload' label='엑셀 파일'>
          <Input type='file' id='upload' changeValue={readExcel} accept='.xlsx' />
        </FormLayout>

        <FormLayout id='resource' label='리소스 파일'>
          <Input type='file' id='resource' changeValue={setResourceFile} multiple />
        </FormLayout>

        {loading && <p className={styles.loading}> 발행중...</p>}
        {!loading && totalNum > 0 && (
          <div className={styles.resultArea}>
            {errorMsg && <p className={styles.errorMsg}>{errorMsg}</p>}

            <p className={styles.totalMsg}>{totalNum > 0 && `${totalNum}/${metaData.length} 완료`}</p>

            {successArr.length > 0 &&
              successArr.map((item) => {
                return (
                  <p className={styles.successMsg} key={Math.random() * 1000}>
                    {item}
                  </p>
                );
              })}

            {failArr.length > 0 &&
              failArr.map((item) => {
                return (
                  <p className={styles.errorMsg} key={Math.random() * 1000}>
                    {item}
                  </p>
                );
              })}
          </div>
        )}

        <div className={styles.btnWrap}>
          <Button isSubmit styleName='editBtn' clickBtn={handleClickMint} text='발행' />
          <Button styleName='cancelBtn' clickBtn={handleCancelBtn} text='취소' />
        </div>
      </form>
    </article>
  );
};

export default BatchMintModal;

import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import Aside from '../Aside/Aside';
import Header from '../Header/Header';

import styles from './layout.module.scss';

const Layout = () => {
  const navigation = useNavigate();

  useEffect(() => {
    if (localStorage.length === 0) {
      alert('로그인 해주세요');
      navigation('/login');
    }
  }, []);

  return (
    <>
      <Header />

      <div className={styles.wrapper}>
        <Aside />
        <main className={styles.main}>
          <div className={styles.container}>
            <Outlet />
          </div>
        </main>
      </div>
    </>
  );
};

export default Layout;

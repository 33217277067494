/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useEffect, useState } from 'react';

import { getDropList } from '../../services/axiosPrivate';

import Pagination from '../../components/Common/Pagination/Pagination';

import styles from './drops.module.scss';
import ModalPortal from '../../components/Modal/ModalPortal';

import Button from '../../components/Common/Button/Button';
import DropCreateModal from '../../components/Modal/DropCreateModal/DropCreateModal';

const Drops = () => {
  const [data, setData] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const listCount = 20;
  const [showCreate, setShowCreate] = useState(false);
  const [editData, setEditData] = useState(null);

  const getUser = async () => {
    const res = await getDropList({ page: currentPage, listCount });
    console.log(res);
    setData(res);
  };

  useEffect(() => {
    getUser();
  }, [currentPage]);

  const handleOpenExcelUploadModal = async () => {
    setEditData(null);
    setShowCreate((prev) => !prev);
  };

  return (
    <section className={styles.userWrap}>
      <h2>Drops</h2>
      <div style={{ width: '150px', marginBottom: '10px' }}>
        <Button text='드롭 배너등록' styleName='uploadBtn' clickBtn={handleOpenExcelUploadModal} />
      </div>
      <article className={styles.tableWrap}>
        <table>
          <thead>
            <tr>
              <th>NFT-ID</th>
              <th>제목</th>
              <th>기간</th>
              <th>이미지</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item: any) => {
              return (
                <tr key={`${item.nftId}_${Math.random() * 1000}`}>
                  <td>{item.nftId}</td>
                  <td>{item.name}</td>
                  <td>{item.eventPeriod}</td>
                  <td>
                    <img alt='thumb' style={{ height: '140px' }} src={item.dropImage} />
                  </td>
                  <td>
                    <div
                      onClick={() => {
                        setEditData(item);
                        setShowCreate(true);
                      }}
                      style={{ color: 'red', fontWeight: 'bold', cursor: 'pointer' }}
                    >
                      수정
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </article>

      <Pagination
        currentPage={currentPage}
        totalElements={data && data.totalElements}
        listCount={listCount}
        setCurrentPage={setCurrentPage}
      />
      {showCreate && (
        <ModalPortal>
          <DropCreateModal isShowModal={showCreate} setIsShowModal={setShowCreate} editData={editData} />
        </ModalPortal>
      )}
    </section>
  );
};

export default Drops;

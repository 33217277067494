import { useEffect, useState } from 'react';
import * as xlsx from 'xlsx';
import toast from 'react-hot-toast';

import { getNftListApi, getNFTTotalCountApi } from '../../../services/axiosPrivate';
import { IExcelMetaDataAttributes, IExcelMetaDataAttributesObj, INftList, INftListParams } from '../../../types/Nft';

import Dropdown from '../../../components/Common/Dropdown/Dropdown';
import Pagination from '../../../components/Common/Pagination/Pagination';
import Button from '../../../components/Common/Button/Button';
import { LoadingModal } from '../../../components/Modal/LoadingModal/LoadingModal';

import styles from './nftList.module.scss';

const NFTList = () => {
  const [nftList, setNftList] = useState([]);
  const [categoryState, setcategoryState] = useState('ALL');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalElements, setTotalElements] = useState(0);
  const [loading, setLoading] = useState(false);

  const listCount = 20;

  const handleNftList = (nftListRes: any) => {
    const rows = nftListRes.map((items: INftList) => {
      console.log(items);
      if (!items.metaData) return null;
      const attributesObj = items.metaData?.attributes?.reduce(
        (acc: IExcelMetaDataAttributesObj, cur: IExcelMetaDataAttributes) => {
          return { ...acc, [cur.trait_type]: cur.value };
        },
        {}
      );

      if (categoryState === 'ALL' || categoryState === 'LAND') {
        return {
          category: items.category,
          name: items.metaData?.name,
          description: items.metaData?.description,
          island: attributesObj?.island,
          usage: attributesObj?.usage,
          scale: attributesObj?.scale,
          form: attributesObj?.form,
          direction: attributesObj?.direction,
          district: attributesObj?.district,
          centroid_x: items.metaData?.centroid_x,
          centroid_y: items.metaData?.centroid_y,
          amount: items.totalAmount,
          id: items.id,
          transactionUrl: items.transactionUrl,
          s3Url: items.s3Url,
          salesStatus: items.salesStatus,
          valid: items.valid,
        };
      }
      if (categoryState === 'EXTERIOR') {
        return {
          category: items.category,
          name: items.metaData?.name,
          description: items.metaData?.description,
          island: attributesObj?.island,
          usage: attributesObj?.usage,
          scale: attributesObj?.scale,
          form: attributesObj?.form,
          amount: items.totalAmount,
          id: items.id,
          transactionUrl: items.transactionUrl,
          s3Url: items.s3Url,
          salesStatus: items.salesStatus,
          valid: items.valid,
        };
      }
      if (categoryState === 'INTERIOR' || categoryState === 'LANDSCAPE') {
        return {
          category: items.category,
          name: items.metaData?.name,
          description: items.metaData?.description,
          island: attributesObj?.island,
          usage: attributesObj?.usage,
          amount: items.totalAmount,
          id: items.id,
          transactionUrl: items.transactionUrl,
          s3Url: items.s3Url,
          salesStatus: items.salesStatus,
          valid: items.valid,
        };
      }
      return null;
    });

    return rows;
  };

  const handleDownLoadBtn = async () => {
    setLoading(true);
    if (totalElements) {
      const data: INftListParams = {
        page: 1,
        listCount: totalElements,
        category: categoryState,
      };

      if (categoryState === 'ALL') {
        delete data.category;
      }

      try {
        const nftListRes = await getNftListApi(data);
        if (nftListRes) {
          const rows = handleNftList(nftListRes);

          const worksheet = xlsx.utils.json_to_sheet(rows);
          const workbook = xlsx.utils.book_new();
          xlsx.utils.book_append_sheet(workbook, worksheet, 'Dates');

          xlsx.writeFile(workbook, `${categoryState}_NFT_LIST.xlsx`);
          setLoading(false);
        }
      } catch (error: any) {
        toast.error('다시 시도해주세요');
      }
    }
  };

  const getNftList = async () => {
    const data: INftListParams = {
      page: currentPage,
      listCount,
      category: categoryState,
    };

    if (categoryState === 'ALL') {
      delete data.category;
    }

    const res = await getNftListApi(data);
    const nftListRes = handleNftList(res);
    setNftList(nftListRes);
  };

  const getTotalElement = async () => {
    const totalCount: { category?: string } = {
      category: categoryState,
    };

    if (categoryState === 'ALL') {
      delete totalCount.category;
    }

    const res = await getNFTTotalCountApi(totalCount);
    setTotalElements(res);
  };

  useEffect(() => {
    getNftList();
  }, [currentPage]);

  useEffect(() => {
    getNftList();
    setCurrentPage(1);
    getTotalElement();
  }, [categoryState]);

  return (
    <>
      <div className={styles.filterWrap}>
        <p>카테고리</p>
        <div className={styles.dropdownWrap}>
          <Dropdown
            setTitleState={setcategoryState}
            data={['ALL', 'LAND', 'EXTERIOR', 'INTERIOR', 'LANDSCAPE']}
            titleState={categoryState}
          />
        </div>
        <Button text='엑셀 다운' styleName='downloadBtn' clickBtn={handleDownLoadBtn} />
      </div>
      <table className={styles.nftList}>
        <thead>
          <tr>
            <th>번호</th>
            <th>NFT ID</th>
            <th>카테고리</th>
            <th>이름</th>
            <th>섬 번호</th>
            <th>용도</th>
            <th>규모</th>
            <th>형태</th>
            <th>수량</th>
            <th>이더스캔</th>
            <th>메타데이터</th>
          </tr>
        </thead>
        <tbody>
          {nftList.length > 0 &&
            nftList.map((item: any, index: number) => {
              return (
                <tr key={Math.random() * 1000} className={item.valid ? '' : `${styles.errorItem}`}>
                  <td>{(currentPage - 1) * listCount + index + 1}</td>
                  <td>{item.id}</td>
                  <td>{item.category}</td>
                  <td>{item.name}</td>
                  <td>{item.island}</td>
                  <td>{item.usage}</td>
                  <td>{item.scale}</td>
                  <td>{item.form}</td>
                  <td>{item.amount}</td>
                  <td>
                    <a href={`https://goerli.etherscan.io/tx/${item.transactionUrl}`} target='_blank' rel='noreferrer'>
                      이더스캔
                    </a>
                  </td>
                  <td>
                    <a href={item.s3Url} target='_blank' rel='noreferrer'>
                      메타데이터
                    </a>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>

      <Pagination
        currentPage={currentPage}
        totalElements={totalElements}
        listCount={listCount}
        setCurrentPage={setCurrentPage}
      />
      {loading && <LoadingModal />}
    </>
  );
};

export default NFTList;

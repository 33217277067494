import { FormEvent, useRef, useState } from 'react';
import * as xlsx from 'xlsx';

import { s3UploadApi } from '../../../services/axiosPrivate';
import useInputFile from '../../../hooks/useInputFile';
import useOnClickOutside from '../../../hooks/useOnClickOutside';

import Button from '../../Common/Button/Button';
import FormLayout from '../../Common/FormLayout/FormLayout';
import Input from '../../Common/Input/Input';

import styles from './resourceUpload.module.scss';

interface IProps {
  isShowModal: boolean;
  setIsShowModal: (value: boolean) => void;
}
const ResourceUploadModal = ({ isShowModal, setIsShowModal }: IProps) => {
  const [errorMsg, setErrorMsg] = useState('');
  const [failArr, setFailArr] = useState<string[]>([]);
  const [successArr, setSuccessArr] = useState<string[]>([]);
  const [totalNum, setTotalNum] = useState(0);
  const [metaData, setMetaData] = useState<any>({});
  const [resourceFile, setResourceFile] = useInputFile([], setFailArr, setSuccessArr, setTotalNum);
  const [loading, setLoading] = useState(false);

  const ref = useRef(null);

  useOnClickOutside(ref, () => {
    if (isShowModal) {
      setIsShowModal(false);
    }
  });

  const handleCancelBtn = () => {
    setIsShowModal(false);
  };

  const readExcel = (e: FormEvent<HTMLInputElement>) => {
    setFailArr([]);
    setSuccessArr([]);
    setTotalNum(0);

    if (!e.currentTarget.files) return;
    const input = e.currentTarget.files;

    const reader = new FileReader();
    if (!input) return;

    reader.onload = () => {
      const data = reader.result;
      const workBook = xlsx.read(data, { type: 'binary' });
      workBook.SheetNames.forEach((sheetName) => {
        const jsonData: any = xlsx.utils.sheet_to_json(workBook.Sheets[sheetName], {
          header: 0,
          blankrows: false,
          range: 4,
        });
        setMetaData(jsonData);
        if (!jsonData[0].ID) {
          setErrorMsg('nft ID가 없습니다.');
        } else {
          setErrorMsg('');
        }
      });
    };

    reader.readAsBinaryString(input[0]);
  };

  const handleClickUpload = async (e: FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setTotalNum(0);
    setFailArr([]);
    setSuccessArr([]);

    if (metaData[0].ID && resourceFile && !errorMsg) {
      setLoading(true);

      for (const items of metaData) {
        const resourceFileArr: any = resourceFile.filter((file: any) => {
          return items.name === file.name.split('_')[0];
        });

        if (resourceFileArr.length !== 0) {
          const formdata: any = new FormData();

          resourceFileArr.map((item: any) => {
            if (item.name.includes('thumb')) {
              formdata.append('thumb', item);
            }
            if (item.name.includes('image')) {
              formdata.append('image', item);
            }
            if (item.name.includes('ref_file1')) {
              formdata.append('refFile1', item);
            }
            if (item.name.includes('ref_file2')) {
              formdata.append('refFile2', item);
            }
          });

          formdata.append('id', items.ID);
          formdata.append('category', items.category);
          formdata.append('metadata', JSON.stringify(items));

          try {
            // eslint-disable-next-line no-await-in-loop
            const uploadRes = await s3UploadApi(formdata);

            if (uploadRes) {
              setLoading(false);
              setSuccessArr((prev) => [...prev, `${items.ID} 업로드 성공`]);
              setTotalNum((prev) => prev + 1);
            }
          } catch (error: any) {
            setLoading(false);
            setFailArr((prev) => [...prev, `${items.ID} 업로드 실패`]);
            setTotalNum((prev) => prev + 1);
          }
        } else {
          setLoading(false);
          setFailArr((prev) => [...prev, `${items.ID}에 해당하는 파일없음`]);
          setTotalNum((prev) => prev + 1);
        }
      }
    } else {
      alert('값을 확인해주세요');
    }
  };

  return (
    <article className={styles.resourceUploadWrap} ref={ref}>
      <p className={styles.notice}>*민팅 성공한 엑셀파일을 첨부해주세요</p>

      <form>
        <FormLayout id='upload' label='엑셀 파일'>
          <Input type='file' id='upload' changeValue={readExcel} accept='.xlsx' />
        </FormLayout>

        <FormLayout id='resource' label='리소스 파일'>
          <Input type='file' id='resource' changeValue={setResourceFile} multiple />
        </FormLayout>

        {loading ? (
          <p className={styles.loading}>업로드중...</p>
        ) : (
          <div className={styles.resultArea}>
            {errorMsg && <p className={styles.errorMsg}>{errorMsg}</p>}

            <p className={styles.totalMsg}>{totalNum > 0 && ` ${totalNum}/${metaData.length} 완료`}</p>

            {successArr.length > 0 &&
              successArr.map((item) => {
                return (
                  <p className={styles.successMsg} key={Math.random() * 1000}>
                    {item}
                  </p>
                );
              })}

            {failArr &&
              failArr.map((item) => {
                return (
                  <p className={styles.errorMsg} key={Math.random() * 1000}>
                    {item}
                  </p>
                );
              })}
          </div>
        )}

        <div className={styles.btnWrap}>
          <Button isSubmit styleName='editBtn' clickBtn={handleClickUpload} text='업로드' />
          <Button styleName='cancelBtn' clickBtn={handleCancelBtn} text='취소' />
        </div>
      </form>
    </article>
  );
};

export default ResourceUploadModal;

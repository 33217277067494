import { atom } from 'recoil';

export const subMenuState = atom<boolean>({
  key: 'subMenuState',
  default: false,
});

export const connectWalletState = atom<boolean>({
  key: 'connectWalletState',
  default: false,
});

export const accessState = atom<string>({
  key: 'accessState',
  default: '',
});

export const adminEditModalState = atom<boolean>({
  key: 'adminEditModalState',
  default: false,
});

export const adminIdState = atom<string>({
  key: 'adminIdState',
  default: '',
});

export const adminGradeState = atom<string>({
  key: 'adminGradeState',
  default: '',
});

import { FormEvent } from 'react';

import styles from './button.module.scss';

interface IProps {
  text: string | number;
  isSubmit?: boolean;
  styleName: string | null;
  clickBtn: (e: FormEvent<HTMLButtonElement>) => void;
}

const Button = ({ text, isSubmit, styleName, clickBtn }: IProps) => {
  return (
    <button
      type={isSubmit ? 'submit' : 'button'}
      className={`${styleName !== null && styles[styleName]}`}
      onClick={clickBtn}
    >
      {text}
    </button>
  );
};
export default Button;

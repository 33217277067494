import { FormEvent, useState } from 'react';
import * as xlsx from 'xlsx';
import toast from 'react-hot-toast';

import { s3UploadApi } from '../../../services/axiosPrivate';
import { getHasRole } from '../../../services/roleManager';
import { landMintApi } from '../../../services/landNFTContract';
import { DIRECTION, DISTRICT, FORM, ISLAND_NAME, ISLAND_NUM, SCALE, USAGE } from '../../../utils';
import { IMintMetaData } from '../../../types/Nft';

import useInput from '../../../hooks/useInput';
import Button from '../../../components/Common/Button/Button';
import ButtonList from '../../../components/Common/ButtonList/ButtonList';
import Dropdown from '../../../components/Common/Dropdown/Dropdown';
import FormLayout from '../../../components/Common/FormLayout/FormLayout';
import Input from '../../../components/Common/Input/Input';

import styles from './landNFT.module.scss';

const LandNFT = () => {
  const [errorMsg, setErrorMsg] = useState('');
  const [island, setIsland] = useState(ISLAND_NUM[0]);
  const [name, setName] = useState({
    island,
    usage: '',
    scale: '',
    form: '',
    centroid_x: '',
    centroid_y: '',
  });
  const [description, setDescription] = useState('');
  const [makerAddress, setMakerAddress, resetMakerAddress] = useInput('', setErrorMsg);
  const [direction, setDirection] = useState(DIRECTION[0]);
  const [district, setDistrict] = useState(DISTRICT[0]);

  const [, , resetThumb, thumb, setThumb] = useInput('', setErrorMsg);
  const [, , resetVideo, video, setVideo] = useInput('', setErrorMsg);
  const [, , resetRhino, rhino, setRhino] = useInput('', setErrorMsg);

  const islandName = ISLAND_NAME.filter((item) => {
    return item.num === island;
  });

  const handleChangeTextarea = (e: FormEvent<HTMLTextAreaElement>) => {
    setDescription(e.currentTarget.value);

    if (errorMsg !== null) {
      setErrorMsg('');
    }
  };

  const changeName = () => {
    setName((prev) => ({ ...prev }));
  };

  const setClick = (value: string, keyName: string) => {
    setName((prev) => ({ ...prev, [keyName]: value }));

    if (errorMsg !== null) {
      setErrorMsg('');
    }
  };

  const setChange = (value: string, keyName: string) => {
    setName((prev) => ({ ...prev, [keyName]: value }));

    if (errorMsg !== null) {
      setErrorMsg('');
    }
  };

  const handleMintNFT = async (e: FormEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (errorMsg !== null) {
      setErrorMsg('');
    }

    if (
      name.centroid_x &&
      name.centroid_y &&
      name.usage &&
      name.scale &&
      name.form &&
      description &&
      district &&
      direction &&
      makerAddress &&
      thumb &&
      video &&
      rhino
    ) {
      try {
        const role = await getHasRole();
        if (role) {
          try {
            const mintResponse = await landMintApi({
              island: name.island,
              usage: name.usage,
              scale: name.scale,
              form: name.form,
              amount: '1',
              makerAddress,
            });

            const formdata: any = new FormData();

            formdata.append('thumb', thumb[0]);
            formdata.append('image', video[0]);
            formdata.append('refFile1', rhino[0]);

            formdata.append('id', mintResponse);
            formdata.append('category', 'LAND');

            formdata.append(
              'metadata',
              JSON.stringify({
                name: `${islandName[0].name} ${name.usage}${name.scale}${name.form} (${name.centroid_x}, ${name.centroid_y})`,
                description,
                island: Number(island),
                usage: name.usage,
                scale: name.scale,
                form: name.form,
                direction,
                district,
                centroid_x: Number(name.centroid_x),
                centroid_y: Number(name.centroid_y),
              })
            );

            if (mintResponse) {
              toast.success(`${mintResponse} 민팅 성공`);
              const rows: IMintMetaData = {
                category: 'LAND',
                name: `${islandName[0].name} ${name.usage}${name.scale}${name.form} (${name.centroid_x}, ${name.centroid_y})`,
                description,
                island,
                usage: name.usage,
                scale: name.scale,
                form: name.form,
                direction,
                district,
                centroid_x: name.centroid_x,
                centroid_y: name.centroid_y,
                maker_address: makerAddress,
                amount: 1,
                nftID: mintResponse,
              };
              const worksheet = xlsx.utils.json_to_sheet([rows]);
              const workbook = xlsx.utils.book_new();

              xlsx.utils.book_append_sheet(workbook, worksheet, 'Dates');

              xlsx.writeFile(workbook, 'LAND_NFT.xlsx');

              try {
                const s3Response = await s3UploadApi(formdata);
                if (s3Response) {
                  toast.success(`${mintResponse} s3 업로드 완료`);
                }
              } catch (error: any) {
                setErrorMsg(`업로드 실패 (${error.message})`);
              } finally {
                setName({
                  island,
                  usage: '',
                  scale: '',
                  form: '',
                  centroid_x: '',
                  centroid_y: '',
                });
                setDescription('');
                setDirection(DIRECTION[0]);
                setDistrict(DISTRICT[0]);
                resetMakerAddress();
                resetThumb();
                resetVideo();
                resetRhino();
              }
            }
          } catch (error: any) {
            setErrorMsg(`민팅 실패 (${error.message})`);
          }
        } else {
          alert('민팅 권한이 없습니다.');
        }
      } catch (error: any) {
        alert('다시 시도해주세요');
      }
    } else {
      alert('필수값들을 모두 입력해주세요');
    }
  };

  return (
    <form className={styles.form}>
      <FormLayout id='Island' label='섬번호'>
        <div className={styles.dropdpownWrap}>
          <Dropdown setTitleState={setIsland} data={ISLAND_NUM} titleState={island} />
        </div>
      </FormLayout>

      <FormLayout id='name' label='이름'>
        <input
          type='text'
          id='name'
          value={`${islandName[0].name} ${name.usage}${name.scale}${name.form} (${name.centroid_x},${name.centroid_y})`}
          onChange={changeName}
        />
      </FormLayout>

      <FormLayout id='description' label='설명'>
        <textarea id='description' value={description} onChange={handleChangeTextarea} />
      </FormLayout>

      <FormLayout id='makerAddress' label='메이커 주소'>
        <Input type='text' id='makerAddress' value={makerAddress} changeValue={setMakerAddress} />
      </FormLayout>

      <FormLayout id='usage' label='용도'>
        <ButtonList
          id='usage'
          data={USAGE}
          active={name.usage}
          keyName='usage'
          setClick={setClick}
          styleName='usageWrap'
        />
      </FormLayout>

      <FormLayout id='scale' label='규모'>
        <ButtonList id='scale' data={SCALE} active={name.scale} keyName='scale' setClick={setClick} />
      </FormLayout>

      <FormLayout id='form' label='형태'>
        <ButtonList id='form' data={FORM} active={name.form} keyName='form' setClick={setClick} />
      </FormLayout>

      <FormLayout id='land_centroid' label='토지좌표'>
        <div className={styles.centroid}>
          <span>X</span>
          <input
            type='text'
            id='land_centroid_x'
            value={name.centroid_x}
            onChange={(e) => setChange(e.currentTarget.value, 'centroid_x')}
          />

          <span>Y</span>
          <input
            type='text'
            id='land_centroid_y'
            value={name.centroid_y}
            onChange={(e) => setChange(e.currentTarget.value, 'centroid_y')}
          />
        </div>
      </FormLayout>

      <FormLayout id='direction' label='향'>
        <div className={styles.dropdpownWrap}>
          <Dropdown setTitleState={setDirection} data={DIRECTION} titleState={direction} />
        </div>
      </FormLayout>

      <FormLayout id='district' label='구역'>
        <div className={styles.dropdpownWrap}>
          <Dropdown setTitleState={setDistrict} data={DISTRICT} titleState={district} />
        </div>
      </FormLayout>

      <FormLayout id='thumb' label='썸네일'>
        <Input type='file' id='thumb' changeValue={setThumb} accept='.png, .jpg, .jpeg' />
      </FormLayout>

      <FormLayout id='video' label='영상'>
        <Input type='file' id='video' changeValue={setVideo} accept='.mp4' />
      </FormLayout>

      <FormLayout id='rhino' label='라이노'>
        <Input type='file' id='rhino' changeValue={setRhino} accept='.3dm' />
      </FormLayout>

      <p className={styles.errorMsg}>{errorMsg}</p>

      <div className={styles.mintingBtnWrap}>
        <Button text='발행' isSubmit styleName='mintingNFT' clickBtn={handleMintNFT} />
      </div>
    </form>
  );
};

export default LandNFT;

import { Contract } from 'web3-eth-contract';
import { contractName, getAccount, getChainId, getContract } from './contract';
import { IFeedParams } from '../types/Nft';

/**
 * NFT 계약에 새로운 피드를 추가합니다.
 * @param {IFeedParams} data - 피드 매개변수.
 * @returns 실패한 경우 null, 그렇지 않으면 트랜잭션 결과를 반환합니다.
 */
export const feedNFTApi = async (data: IFeedParams): Promise<any | null> => {
  try {
    const chainId = await getChainId();
    const account = await getAccount();
    const contract = getContract(chainId, contractName.FeedNFT) as Contract;

    // 계약 메소드를 실행하여 새로운 피드를 추가합니다.
    return await contract.methods
      .addFeed(data.buildingId, data.feeToken, data.feeAmount, data.limit, data.enable)
      .send({ from: account });
  } catch (error) {
    // 실패 시 콘솔에 에러를 기록하고 null을 반환합니다.
    console.error('Failed to add feed to NFT:', error);
    return null;
  }
};

import axios from 'axios';
import { ILogin } from '../types/User';

const BASE_URL = process.env.REACT_APP_BASEURL;

export const getLoginApi = async (params: ILogin) => {
  try {
    const res = await axios.post(`${BASE_URL}/admin/login`, {
      id: params.id,
      password: params.password,
    });

    return res.data;
  } catch (error: any) {
    throw new Error(error.response.data.message);
  }
};

export const updateAccessTockenApi = async () => {
  const localTocken = localStorage.getItem('token') && localStorage.getItem('token');
  const tockens = localTocken && JSON.parse(localTocken);

  try {
    const res = await axios.get(`${BASE_URL}/token/refresh`, {
      headers: { Authorization: `Bearer ${tockens?.refreshToken}` },
    });

    const newAccessToken = res.data.data.accessToken;

    return newAccessToken;
  } catch (error: any) {
    localStorage.removeItem('token');
    localStorage.removeItem('userInfo');
    localStorage.removeItem('wallet');
    throw new Error(error.message);
  }
};

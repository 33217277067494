import styles from './nftButton.module.scss';

interface IProps {
  text: string | number;
  isSubmit?: boolean;
  styleName: string | null;
  keyName: string;
  clickBtn: (value: string, keyName: string) => void;
}

const NftButton = ({ text, isSubmit, keyName, styleName, clickBtn }: IProps) => {
  return (
    <button
      type={isSubmit ? 'submit' : 'button'}
      className={`${styleName !== null && styles[styleName]}`}
      onClick={(e) => clickBtn(e.currentTarget.value, keyName)}
      value={text}
    >
      {text}
    </button>
  );
};
export default NftButton;

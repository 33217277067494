import { useEffect } from 'react';

const useOnClickOutside = (ref: any, handler: () => void) => {
  const clickOutside = (e: MouseEvent) => {
    if (!ref.current) return;
    if (ref.current && !ref.current.contains(e.target as Node)) {
      handler();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', clickOutside);
    return () => {
      document.removeEventListener('mousedown', clickOutside);
    };
  });
};

export default useOnClickOutside;

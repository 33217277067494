import { useEffect, useState } from 'react';
import Button from '../Button/Button';

import styles from './pagination.module.scss';

interface IProps {
  currentPage: number;
  totalElements?: number;
  listCount: number;
  setCurrentPage: (value: number) => void;
}

const Pagination = ({ currentPage, totalElements, listCount, setCurrentPage }: IProps) => {
  const [totalPages, setTotalPages] = useState(1);
  const [pagination, setPagination] = useState<number[]>([]);

  const handleClickPage = (pageValue: number) => {
    setCurrentPage(pageValue);
  };

  const handleClickNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleClickPrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleClickFirst = () => {
    setCurrentPage(1);
  };

  const handleClickLast = () => {
    setCurrentPage(totalPages);
  };

  const handlePaging = () => {
    if (totalElements) {
      const totalPage = Math.ceil(totalElements / listCount);
      setTotalPages(totalPage);

      let startPage: number;
      let endPage: number;

      if (totalPage <= 10) {
        startPage = 1;
        endPage = totalPage;
      } else if (currentPage <= 6) {
        startPage = 1;
        endPage = 10;
      } else if (currentPage + 4 >= totalPage) {
        startPage = totalPage - 9;
        endPage = totalPage;
      } else {
        startPage = currentPage - 5;
        endPage = currentPage + 4;
      }

      setPagination([...Array(endPage + 1 - startPage).keys()].map((i) => startPage + i));
    }
  };

  useEffect(() => {
    handlePaging();
  }, [totalElements, currentPage]);

  return (
    <ol className={styles.pagination}>
      <li className={styles.first}>
        <Button text=' &lt;&lt;' styleName={currentPage === 1 ? 'disabled' : null} clickBtn={handleClickFirst} />
      </li>
      <li>
        <Button text='&lt; 이전' styleName={currentPage === 1 ? 'disabled' : null} clickBtn={handleClickPrev} />
      </li>
      {pagination.map((item: number) => {
        return (
          <li key={Math.random() * 1000}>
            <Button
              text={item}
              styleName={item === currentPage ? 'active' : null}
              clickBtn={() => handleClickPage(item)}
            />
          </li>
        );
      })}
      <li>
        <Button
          text=' 다음 &gt;'
          styleName={currentPage === totalPages ? 'disabled' : null}
          clickBtn={handleClickNext}
        />
      </li>
      <li className={styles.last}>
        <Button
          text=' &gt;&gt;'
          styleName={currentPage === totalPages ? 'disabled' : null}
          clickBtn={handleClickLast}
        />
      </li>
    </ol>
  );
};

export default Pagination;

import { useEffect } from 'react';
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core';
import { InjectedConnector, NoEthereumProviderError, UserRejectedRequestError } from '@web3-react/injected-connector';
import { Link } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import toast, { Toaster } from 'react-hot-toast';

import SubMenu from '../Common/SubMenu/SubMenu';
import { connectWalletState, subMenuState } from '../../recoil/recoil';
import Button from '../Common/Button/Button';

import styles from './header.module.scss';

const Header = () => {
  const wallet = localStorage.getItem('wallet');
  const userInfo = localStorage.getItem('userInfo');
  const accounts = userInfo && JSON.parse(userInfo);

  const setIsOpenAccountMenu = useSetRecoilState(subMenuState);
  const setIsConnectWallet = useSetRecoilState(connectWalletState);

  const { account, active, activate, deactivate } = useWeb3React();

  const injected = new InjectedConnector({
    supportedChainIds: [1, 11155111, 421614, 42161],
  });

  const handleConnectWallet = async () => {
    try {
      await activate(injected, (error) => {
        if (error instanceof NoEthereumProviderError) {
          toast.error('Metamask 익스텐션을 설치해주세요');
        } else if (error instanceof UnsupportedChainIdError) {
          toast.error('지원되지 않는 네트워크에 연결되었습니다.');
        } else if (error instanceof UserRejectedRequestError) {
          toast.error('계정에 접근할 수 있도록 연결해 주세요.');
        }
      });
    } catch (error: any) {
      toast.error(error);
    }
  };

  const handleDisconnectWallet = () => {
    deactivate();
    localStorage.removeItem('wallet');
  };

  const handleShowSubMenu = () => {
    setIsOpenAccountMenu((prev) => !prev);
  };

  const handleCheckConnectWallet = () => {
    if (account) {
      localStorage.setItem('wallet', account);
      setIsConnectWallet(true);
    } else {
      setIsConnectWallet(false);
    }
  };

  useEffect(() => {
    handleCheckConnectWallet();
  }, [account]);

  useEffect(() => {
    if (wallet !== null) {
      handleConnectWallet();
    }
  }, []);

  return (
    <header className={styles.header}>
      <Link to='/' className={styles.logo}>
        STELSI
      </Link>
      <ul className={styles.user}>
        <li>
          {active ? (
            <Button text={`${account} 연결 해제`} styleName={null} clickBtn={handleDisconnectWallet} />
          ) : (
            <button type='button' onClick={handleConnectWallet}>
              <span className='material-symbols-outlined'>account_balance_wallet </span>
              wallet
            </button>
          )}
        </li>
        <li>
          <button type='button' onClick={handleShowSubMenu}>
            <span className='material-symbols-outlined'>account_circle </span>
            {accounts.id}
          </button>
          <SubMenu />
        </li>
      </ul>

      <Toaster
        position='top-center'
        toastOptions={{
          className: `${styles.toast}`,
        }}
      />
    </header>
  );
};

export default Header;

import {ChangeEvent, FormEvent, useState } from 'react';
import Button from '../../Common/Button/Button';
import FormLayout from '../../Common/FormLayout/FormLayout';
import Input from '../../Common/Input/Input';
import { LoadingModal } from '../LoadingModal/LoadingModal';
import { updateMaker } from '../../../services/axiosPrivate';
import styles from './makerUpdateModal.module.scss';

interface IProps {
  isShowModal: boolean;
  setIsShowModal: (value: boolean) => void;
  editData: any;
}

interface ApprovalStatus {
  id: number;
  statusName: string;
}

const MakerUpdateModal = ({ isShowModal, setIsShowModal, editData }: IProps) => {
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState(editData);

  const handleCancelBtn = () => {
    setIsShowModal(!isShowModal);
  };

  const approvalStatusOptions: ApprovalStatus[] = [
    { id: 1, statusName: 'pending' },
    { id: 2, statusName: 'approved' },
    { id: 3, statusName: 'rejected' }
  ];

  const handleApprovalStatusChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    const selectedStatus = approvalStatusOptions.find(
        option => option.id === parseInt(e.target.value, 10)
    );

    if (selectedStatus) {
      // @ts-ignore
      handleChange({
        target: {
          id: 'approvalMakerStatus',
          value: JSON.stringify(selectedStatus)
        }
      } as React.ChangeEvent<HTMLSelectElement>);
    }
  };

  const handleClickAdd = async (e: FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    try {
      setLoading(true);

      const formData = new FormData();
      Object.entries(values).forEach(([key, value]) => {
        if (value instanceof File) {
          formData.append(key, value);
        } else if (key === 'approvalMakerStatus') {
          formData.append(key, JSON.stringify(value));
        } else {
          // @ts-ignore
          formData.append(key, value);
        }
      });

      await updateMaker(values.seq, formData);

    } catch (error: any) {
      console.error(error.message);
    } finally {
      setLoading(false);
      setIsShowModal(!isShowModal);
      window.location.href = '/maker';
    }
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setValues((prevValues: any) => ({
      ...prevValues,
      [id]: value,
    }));
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, files } = e.target;
    if (files && files[0]) {
      setValues((prev: any) => ({
        ...prev,
        [id]: files[0]
      }));
    }
  };

  return (
      <article className={styles.whiteListWrap}>
        <form>
          <FormLayout id='logo' label='로고'>
            <Input type='file' id='logo' changeValue={handleFileChange} />
          </FormLayout>
          <FormLayout id='topBanner' label='탑배너'>
            <Input type='file' id='topBanner' changeValue={handleFileChange} />
          </FormLayout>
          <FormLayout id='nickname' label='닉네임'>
            <Input value={values.nickname} type={'text'} id={'nickname'} changeValue={handleChange} />
          </FormLayout>
          <FormLayout id='activity' label='활동'>
            <Input value={values.activity} type={'text'} id={'activity'} changeValue={handleChange} />
          </FormLayout>
          <FormLayout id='introComment' label='인트로 코멘트'>
            <Input value={values.introComment} type={'text'} id={'introComment'} changeValue={handleChange} />
          </FormLayout>
          <FormLayout id='website' label='웹사이트'>
            <Input value={values.website} type={'text'} id={'website'} changeValue={handleChange} />
          </FormLayout>
          <FormLayout id='youtube' label='유튜브'>
            <Input value={values.youtube} type={'text'} id={'youtube'} changeValue={handleChange} />
          </FormLayout>
          <FormLayout id='instagram' label='인스타그램'>
            <Input type='text' id='instagram' value={values.instagram} changeValue={handleChange} />
          </FormLayout>
          <FormLayout id='twitter' label='트위터'>
            <Input value={values.twitter} type={'text'} id={'twitter'} changeValue={handleChange} />
          </FormLayout>
          <FormLayout id='approvalMakerStatus' label='승인상태'>
            <select
                id='approvalMakerStatus'
                name='approvalMakerStatus'
                value={values.approvalMakerStatus.id}
                onChange={handleApprovalStatusChange}
            >
              {approvalStatusOptions.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.statusName}
                  </option>
              ))}
            </select>
          </FormLayout>
          <div className={styles.btnWrap}>
            <Button isSubmit styleName='addBtn' clickBtn={handleClickAdd} text='수정'/>
            <Button styleName='cancelBtn' clickBtn={handleCancelBtn} text='취소'/>
          </div>
        </form>
        {loading ? <LoadingModal/> : null}
      </article>
  );
};

export default MakerUpdateModal;

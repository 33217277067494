// @ts-nocheck
/* eslint-disable no-alert */
import { FormEvent, useEffect, useRef, useState } from 'react';

import useOnClickOutside from '../../../hooks/useOnClickOutside';

import Button from '../../Common/Button/Button';
import FormLayout from '../../Common/FormLayout/FormLayout';

import styles from './bannerCreateModal.module.scss';
import Input from '../../Common/Input/Input';
import { LoadingModal } from '../LoadingModal/LoadingModal';
import { createBanner, editBanner } from '../../../services/axiosPrivate';

interface IProps {
  isShowModal: boolean;
  setIsShowModal: (value: boolean) => void;
  editData: any;
}

const BannerCreateModal = ({ isShowModal, setIsShowModal, editData }: IProps) => {
  const [loading, setLoading] = useState(false);
  const [bannerImage, setBannerImage] = useState('');
  const [bannerLink, setBannerLink] = useState('');
  const [enabled, setEnabled] = useState(true);
  const [startTime, setStartTime] = useState(0);
  const [endTime, setEndTime] = useState(0);

  const ref = useRef(null);

  useEffect(() => {
    if (editData) {
      setBannerLink(editData.bannerLink);
      const e = editData.enable === 1;
      setEnabled(e);
      setStartTime(editData.startTime);
      setEndTime(editData.endTime);
    }
  }, [editData]);

  useOnClickOutside(ref, () => {
    if (isShowModal) {
      setIsShowModal(false);
    }
  });

  const handleCancelBtn = () => {
    setIsShowModal(false);
  };

  const handleClickAdd = async (e: FormEvent<HTMLButtonElement>) => {
    e.preventDefault();

    // if (bannerImage.length === 0 || bannerLink.length === 0) {
    //   alert('빈칸을 채워주세요');
    //   return;
    // }

    try {
      setLoading(true);

      const formData = new FormData(); // formData 객체를 생성한다.

      let r;
      if (editData) {
        if (bannerImage.length > 0) {
          formData.append('bannerImage', bannerImage[0]);
        }
        formData.append('bannerLink', bannerLink);
        formData.append('enable', enabled ? 1 : 0);
        formData.append('startTime', startTime);
        formData.append('endTime', endTime);

        r = await editBanner(formData, editData.seq);
      } else {
        formData.append('bannerImage', bannerImage[0]);
        formData.append('bannerLink', bannerLink);
        formData.append('enable', enabled ? 1 : 0);
        formData.append('startTime', startTime);
        formData.append('endTime', endTime);
        r = await createBanner(formData);
      }
      console.log(r);
      if (!r) {
        alert('등록되었습니다.');
        window.location.href = '/banner';
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <article className={styles.whiteListWrap} ref={ref}>
      <form>
        <FormLayout id='bannerImage' label='이미지'>
          <Input type='file' id='bannerImage' changeValue={(e) => setBannerImage(e.target.files)} />
        </FormLayout>
        <FormLayout id='bannerLink' label='배너링크'>
          <Input type='text' id='bannerLink' value={bannerLink} changeValue={(e) => setBannerLink(e.target.value)} />
        </FormLayout>

        <FormLayout id='startTime' label='시작시간(유닉스타임)'>
          <Input type='number' id='startTime' value={startTime} changeValue={(e) => setStartTime(e.target.value)} />
        </FormLayout>
        <FormLayout id='endTime' label='종료시간(유닉스타임)'>
          <Input type='number' id='endTime' value={endTime} changeValue={(e) => setEndTime(e.target.value)} />
        </FormLayout>
        <FormLayout id='enable' label='배너활성화'>
          <input
            type='checkbox'
            id='enabled'
            className={styles.checkbox}
            checked={enabled}
            onChange={(e) => setEnabled(e.target.checked)}
          />
        </FormLayout>
        <div className={styles.btnWrap}>
          <Button isSubmit styleName='addBtn' clickBtn={handleClickAdd} text='추가' />
          <Button styleName='cancelBtn' clickBtn={handleCancelBtn} text='취소' />
        </div>
      </form>
      {loading ? <LoadingModal /> : null}
    </article>
  );
};

export default BannerCreateModal;

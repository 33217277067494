// @ts-nocheck
/* eslint-disable no-alert */
import { FormEvent, useEffect, useRef, useState } from 'react';

import useOnClickOutside from '../../../hooks/useOnClickOutside';

import Button from '../../Common/Button/Button';
import FormLayout from '../../Common/FormLayout/FormLayout';

import styles from './dropCreateModal.module.scss';
import Input from '../../Common/Input/Input';
import { LoadingModal } from '../LoadingModal/LoadingModal';
import { createDrop, editDrops } from '../../../services/axiosPrivate';

interface IProps {
  isShowModal: boolean;
  setIsShowModal: (value: boolean) => void;
  editData: any;
}

const DropCreateModal = ({ isShowModal, setIsShowModal, editData }: IProps) => {
  const [loading, setLoading] = useState(false);
  const [nftId, setNftId] = useState('');
  const [dropImage, setDropImage] = useState('');
  const [name, setName] = useState('');

  const ref = useRef(null);

  useOnClickOutside(ref, () => {
    if (isShowModal) {
      setIsShowModal(false);
    }
  });

  useEffect(() => {
    if (editData) {
      console.log(editData);
      setNftId(editData.nftId);
      setName(editData.name);
    }
  }, [editData]);

  const handleCancelBtn = () => {
    setIsShowModal(false);
  };

  const handleClickAdd = async (e: FormEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (nftId.length === 0) {
      alert('빈칸을 채워주세요');
      return;
    }

    try {
      setLoading(true);
      const formData = new FormData(); // formData 객체를 생성한다.

      let r;
      if (editData) {
        if (dropImage.length > 0) {
          formData.append('dropImage', dropImage[0]);
        }
        formData.append('nftId', nftId);
        formData.append('name', name);
        r = await editDrops(formData, editData.seq);
      } else {
        formData.append('nftId', nftId);
        formData.append('name', name);
        formData.append('dropImage', dropImage[0]);
        r = await createDrop(formData);
      }

      if (!r) {
        alert('등록되었습니다.');
        window.location.href = '/drop';
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <article className={styles.whiteListWrap} ref={ref}>
      <form>
        <FormLayout id='nftId' label='nftId'>
          <Input type='text' id='nftId' value={nftId} changeValue={(e) => setNftId(e.target.value)} />
        </FormLayout>
        <FormLayout id='name' label='제목'>
          <Input type='text' id='name' value={name} changeValue={(e) => setName(e.target.value)} />
        </FormLayout>

        <FormLayout id='dropImage' label='이미지'>
          <Input type='file' id='upload' changeValue={(e) => setDropImage(e.target.files)} />
        </FormLayout>

        <div className={styles.btnWrap}>
          <Button isSubmit styleName='addBtn' clickBtn={handleClickAdd} text='추가' />
          <Button styleName='cancelBtn' clickBtn={handleCancelBtn} text='취소' />
        </div>
      </form>
      {loading ? <LoadingModal /> : null}
    </article>
  );
};

export default DropCreateModal;

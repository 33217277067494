import { FormEvent, useRef, useState } from 'react';
import useOnClickOutside from '../../../hooks/useOnClickOutside';
import Button from '../Button/Button';

import styles from './dropdown.module.scss';

interface IProps {
  data: string[];
  titleState: string;
  setTitleState: (value: string) => void;
}

const Dropdown = ({ data, titleState, setTitleState }: IProps) => {
  const [isShow, setIsShow] = useState(false);
  const ref = useRef(null);

  useOnClickOutside(ref, () => {
    if (isShow) {
      setIsShow(false);
    }
  });

  const handleClickOpen = () => {
    setIsShow((prev) => !prev);
  };

  const handleClickButton = (e: FormEvent<HTMLButtonElement>) => {
    const value = e.currentTarget.innerText;
    setTitleState(value);
    setIsShow(false);
  };

  return (
    <div className={styles.dropdown} ref={ref}>
      <Button text={titleState} styleName={null} clickBtn={handleClickOpen} />

      {isShow && (
        <ul className={styles.dropdownContents}>
          {data.map((item) => {
            return (
              <li key={Math.random() * 1000}>
                <Button text={item} styleName={null} clickBtn={handleClickButton} />
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;

// @ts-nocheck
/* eslint-disable no-alert */
import { FormEvent, useRef, useState } from 'react';

import useOnClickOutside from '../../../hooks/useOnClickOutside';

import Button from '../../Common/Button/Button';
import FormLayout from '../../Common/FormLayout/FormLayout';

import styles from './feedCreateModal.module.scss';
import Input from '../../Common/Input/Input';
import { createBuildingNft } from '../../../services/axiosPrivate';
import { LoadingModal } from '../LoadingModal/LoadingModal';
import { feedNFTApi } from '../../../services/feedNFTContract';

interface IProps {
  isShowModal: boolean;
  setIsShowModal: (value: boolean) => void;
}

const FeedCreateModal = ({ isShowModal, setIsShowModal }: IProps) => {
  const [loading, setLoading] = useState(false);
  const [buildingNftId, setBuildingNftId] = useState('');
  const [feeTokenAddress, setFeeTokenAddress] = useState('');
  const [feedLimit, setFeedLimit] = useState(1000000);
  const [feedType, setFeedType] = useState('');
  const [feedName, setFeedName] = useState('');
  const [feedDescription, setFeedDescription] = useState('');
  const [feedImage, setFeedImage] = useState('');
  const [feedThumbnail, setFeedThumbnail] = useState('');
  const [onlyMetadata, setOnlyMetadata] = useState(false);

  const ref = useRef(null);

  useOnClickOutside(ref, () => {
    if (isShowModal) {
      setIsShowModal(false);
    }
  });

  const handleCancelBtn = () => {
    setIsShowModal(false);
  };

  const handleClickAdd = async (e: FormEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (onlyMetadata) {
      if (
        buildingNftId.length === 0 ||
        feedType.length === 0 ||
        feedName.length === 0 ||
        feedDescription.length === 0 ||
        feedImage.length === 0 ||
        feedThumbnail.length === 0
      ) {
        alert('빈칸을 채워주세요');
        return;
      }
    } else if (
      buildingNftId.length === 0 ||
      feeTokenAddress.length === 0 ||
      feedType.length === 0 ||
      feedName.length === 0 ||
      feedDescription.length === 0 ||
      feedImage.length === 0 ||
      feedThumbnail.length === 0
    ) {
      alert('빈칸을 채워주세요');
      return;
    }
    try {
      setLoading(true);
      if (!onlyMetadata) {
        const res = await feedNFTApi({
          buildingId: parseInt(buildingNftId, 10),
          feeToken: feeTokenAddress,
          feeAmount: 0,
          limit: feedLimit,
          enable: true,
        });
        console.log(res);
      }

      const formData = new FormData(); // formData 객체를 생성한다.
      formData.append('buildingNftId', buildingNftId);
      formData.append('feedType', feedType);
      formData.append('feedName', feedName);
      formData.append('feedDescription', feedDescription);
      formData.append('feedImage', feedImage[0]);
      formData.append('feedThumbnail', feedThumbnail[0]);
      const r = await createBuildingNft(formData);
      console.log(r);
      if (!r) {
        alert('프로필이 등록되었습니다.');
        window.location.href = '/feed';
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <article className={styles.whiteListWrap} ref={ref}>
      <form>
        <FormLayout id='feeTokenAddress' label='feeTokenAddress'>
          <Input
            type='text'
            id='feeTokenAddress'
            value={feeTokenAddress}
            changeValue={(e) => setFeeTokenAddress(e.target.value)}
          />
        </FormLayout>
        <FormLayout id='feedLimit' label='개수'>
          <Input type='number' id='feedLimit' value={feedLimit} changeValue={(e) => setFeedLimit(e.target.value)} />
        </FormLayout>
        <FormLayout id='onlyMetadata' label='메타데이터만 등록'>
          <input
            type='checkbox'
            id='onlyMetadata'
            className={styles.checkbox}
            checked={!!onlyMetadata}
            onChange={(e) => setOnlyMetadata(e.target.checked)}
          />
        </FormLayout>
        <FormLayout id='buildingNftId' label='빌딩NFT ID'>
          <Input
            type='text'
            id='buildingNftId'
            value={buildingNftId}
            changeValue={(e) => setBuildingNftId(e.target.value)}
          />
        </FormLayout>
        <FormLayout id='feedType' label='피드타입'>
          <Input type='text' value={feedType} id='feedType' changeValue={(e) => setFeedType(e.target.value)} />
        </FormLayout>
        <FormLayout id='feedName' label='피드네임'>
          <Input type='text' value={feedName} id='feedName' changeValue={(e) => setFeedName(e.target.value)} />
        </FormLayout>
        <FormLayout id='feedDescription' label='피드설명'>
          <textarea id='feedDescription' value={feedDescription} onChange={(e) => setFeedDescription(e.target.value)} />
        </FormLayout>
        <FormLayout id='feedImage' label='피드이미지'>
          <Input type='file' id='upload' changeValue={(e) => setFeedImage(e.target.files)} />
        </FormLayout>
        <FormLayout id='feedThumbnail' label='피드썸네일'>
          <Input type='file' id='upload' changeValue={(e) => setFeedThumbnail(e.target.files)} />
        </FormLayout>

        <div className={styles.btnWrap}>
          <Button isSubmit styleName='addBtn' clickBtn={handleClickAdd} text='추가' />
          <Button styleName='cancelBtn' clickBtn={handleCancelBtn} text='취소' />
        </div>
      </form>
      {loading ? <LoadingModal /> : null}
    </article>
  );
};

export default FeedCreateModal;

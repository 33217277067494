// login
export const LOGIN_ROOT = [
  {
    code: '00',
    name: '-',
  },
  {
    code: '01',
    name: 'google',
  },
  {
    code: '02',
    name: 'facebook',
  },
  {
    code: '03',
    name: 'apple',
  },
  {
    code: '04',
    name: 'twitter',
  },
];

// user

// nft
export const TAB_LIST = ['NFT 목록', 'Land NFT 발행', 'Building NFT 발행'];
export const PRIVILEGES = ['00', '10'];
export const TYPE = ['land', 'exterior', 'interior', 'landScapes'];
export const DIRECTION = ['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW'];
export const DISTRICT = ['Beach Circle', 'Middle Circle', 'Market Circle', 'Central Circle'];
export const NFT_LIST = [
  {
    name: 'Hola Land',
    type: 'land',
  },
];
export const ISLAND_NUM = ['10'];
export const ISLAND_NAME = [{ num: '10', name: 'HOLA' }];
export const USAGE = ['R', 'C', 'I', 'O'];
export const SCALE = ['1', '2', '3'];
export const FORM = ['A', 'B', 'C', 'D', 'E'];

// admin
// export const ADMIN_TAB_LIST = ['관리자 목록', '관리자 생성', '권한 체크 리스트', 'URI'];
export const ADMIN_TAB_LIST = ['관리자 목록', '관리자 생성', 'URI'];

// open sale
export const TAB_OPEN_SALE = ['오픈 세일 리스트', '권한 등록', '판매 등록', '라운드 관리', '화이트 리스트'];
export const ROUND = ['ALL', '1', '2', '3', '4'];
export const SALE = ['ALL', '판매중', '판매완료'];

export const isProduction = process.env.REACT_APP_MODE === 'production';
// console.log('process.env.NODE_ENV', process.env.REACT_APP_MODE);
// console.log('process.env.REACT_APP_BASEURL', process.env.REACT_APP_BASEURL);
// console.log('isProduction', isProduction);

import Web3 from 'web3';
import { Contract } from 'web3-eth-contract';
import { IMintParams } from '../types/Nft';
import {
  contractName,
  getAccount,
  getChainId,
  getContract,
  MainContractAddress,
  TestContractAddress,
} from './contract';

const web3 = new Web3((window as any).ethereum);
// balance
export const getBalance = async (account: string, tokenAddress?: string) => {
  let balance = '0';

  try {
    const chainId = await web3.eth.getChainId();
    if (tokenAddress === undefined) {
      balance = await web3.eth.getBalance(account);
      balance = web3.utils.fromWei(balance, 'ether');
    } else {
      balance = await (getContract(chainId, contractName.NFTBase) as Contract).methods.balanceOf(account);
    }
  } catch (error: any) {
    throw new Error(error.message);
  }
  return balance;
};

// token URI
export const getURI = async (id: string) => {
  const chainId = await getChainId();
  try {
    const res = await (getContract(chainId, contractName.NFTBase) as Contract).methods.uri(id).call();
    return res;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

// token URI
export const setURI = async (newUri: string) => {
  const chainId = await getChainId();
  const account = await getAccount();
  try {
    const res = await (getContract(chainId, contractName.NFTBase) as Contract).methods.setURI(newUri).send({
      from: account,
    });
    return res;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

// approved 체크
export const isApprovedForAllApi = async () => {
  const chainId = await getChainId();
  const account = await getAccount();
  try {
    const res = await (getContract(chainId, contractName.NFTBase) as Contract).methods
      .isApprovedForAll(account, chainId === 1 ? MainContractAddress.OpenSale : TestContractAddress.OpenSale)
      .call();
    return res;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

// approved
export const setApprovalForAllApi = async (approvedState: boolean) => {
  const chainId = await getChainId();
  const account = await getAccount();
  try {
    const res = await (getContract(chainId, contractName.NFTBase) as Contract).methods
      .setApprovalForAll(chainId === 1 ? MainContractAddress.OpenSale : TestContractAddress.OpenSale, approvedState)
      .send({
        from: account,
      });
    return res;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const findIds = async (eventText: string, receipt: any) => {
  const chainId = await getChainId();
  const idText = eventText === 'TransferBatch' ? 'ids' : 'id';

  const transferBatchInput = await (
    getContract(chainId, contractName.NFTBase) as Contract
  ).options.jsonInterface.filter((item: any) => {
    return item.name === eventText;
  });
  const typeArray: any = transferBatchInput[0].inputs?.filter((item: any) => {
    return item.name === idText;
  });

  const transferBatchHexValue = await web3.eth.abi.encodeEventSignature({
    anonymous: false,
    inputs: transferBatchInput[0].inputs,
    name: eventText,
    type: 'event',
  });
  const dataHexValue = Object.entries(receipt.events).map((item: any) => {
    if (
      item[1].raw.topics[0] === transferBatchHexValue &&
      item[1].raw.topics[2] === '0x0000000000000000000000000000000000000000000000000000000000000000'
    ) {
      return item[1].raw.data;
    }
    return null;
  });

  const idsValues: any = await web3.eth.abi.decodeParameters([`${typeArray[0].type}`], dataHexValue[0]);
  return idsValues[0];
};

// batch mint
export const batchMintApi = async (category: string, data: IMintParams) => {
  const chainId = await getChainId();
  const account = await getAccount();

  let res;

  try {
    if (category === 'LAND') {
      res = await (getContract(chainId, contractName.LandNFT) as Contract).methods
        .mintBatch(data.island, data.usage, data.scale, data.form, data.amount, data.makerAddress)
        .send({
          from: account,
        });
    }
    if (category === 'BUILDING') {
      res = await (getContract(chainId, contractName.BuildingNFT) as Contract).methods
        .mintBatch(data.island, data.usage, data.scale, data.form, data.amount, data.makerAddress)
        .send({
          from: account,
        });
    }

    if (res) {
      const idsResult = findIds('TransferBatch', res);
      return idsResult;
    }
    return [];
  } catch (error: any) {
    throw new Error(error.message);
  }
};

import { ChangeEvent, useState } from 'react';

const useInput = (
  initialState: string,
  setErrorMsg: any,
  isIdCheck?: any,
  setIsIdCheck?: any
): [string, (e: ChangeEvent<HTMLInputElement>) => void | string, () => void, string[], any] => {
  const [inputState, setInputState] = useState(initialState);
  const [fileState, setFileState] = useState([]);

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value.trim();
    setInputState(value);

    if (isIdCheck) {
      setIsIdCheck(false);
    }

    if (setErrorMsg !== null) {
      setErrorMsg('');
    }
  };

  const handleSelectFile = (e: any) => {
    const arr: any = [];
    const value = e.currentTarget.files;
    for (const file of value) {
      arr.push(file);
    }
    setFileState(arr);
  };
  const resetState = () => {
    setInputState('');
    setFileState([]);
  };
  return [inputState, handleChangeInput, resetState, fileState, handleSelectFile];
};

export default useInput;

import { useEffect, useState } from 'react';
import {getMakers} from '../../services/axiosPrivate';
import Pagination from '../../components/Common/Pagination/Pagination';
import ModalPortal from '../../components/Modal/ModalPortal';
import styles from './maker.module.scss';
import MakerUpdateModal from '../../components/Modal/MakerUpdateModal/MakerUpdateModal';
import Select from '../../components/Common/Select/Select';

const Maker = () => {
  const [data, setData] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const listCount = 20;
  const [showCreate, setShowCreate] = useState(false);
  const [editData, setEditData] = useState(null);
  const [status, setStatus] = useState<any>('all');

  const getMaker = async (status?: string) => {
    const res = await getMakers({ page: currentPage, limit: listCount,  status });
    setData(res);
  };

  type ProjectStatusType = {
    [key: number]: string;
  };
  const ProjectStatus: ProjectStatusType = {
    0: 'all',
    1: 'pending',
    2: 'approved',
  };
  const getStatusOptions = () => {
    return Object.entries(ProjectStatus).map(([key, value]) => ({ value: key, label: value }))
  }
  const handleChange = (e: any) => {
    const { value } = e.target;

    setStatus(value);
  };
  


  useEffect(() => {
    getMaker(ProjectStatus[status] ?? 'all');
  }, [currentPage, status]);
  
  return (
      <section className={styles.userWrap}>
        <h2>메이커</h2>
        <Select
            value={status}
            id='status'
            onChange={handleChange}
            options={getStatusOptions()}
        />
        <article className={styles.tableWrap}>
          <table>
            <thead>
            <tr>
              <th>seq</th>
              <th>로고</th>
              <th>탑배너</th>
              <th>닉네임</th>
              <th>활동이력</th>
              <th>소개글</th>
              <th>웹사이트</th>
              <th>유튜브</th>
              <th>인스타그램</th>
              <th>트위터</th>
              <th>승인여부</th>
            </tr>
            </thead>
            
            <tbody>
            {data.map((item: any) => {
              return (
                  <tr key={`${item.nftId}_${Math.random() * 1000}`}>
                    <td>{item.seq}</td>
                    <td style={{
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      maxWidth: '150px',
                      minWidth: '75px'
                    }}>
                      <img alt='thumb' style={{height: '50px', width: '50px'}} src={item.logo ?? ''}/>
                    </td>
                    <td style={{
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      maxWidth: '150px',
                      minWidth: '75px'
                    }}>
                      <img alt='thumb' style={{height: '50px', width: '50px'}} src={item.topBanner ?? ''}/>
                    </td>
                    <td style={{
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      maxWidth: '100px'
                    }}>{item.nickname}</td>
                    <td style={{
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      maxWidth: '100px'
                    }}>{item.activity}</td>
                    <td style={{
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      maxWidth: '100px'
                    }}>{item.introComment}</td>
                    <td style={{
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      maxWidth: '100px'
                    }}>{item.website}</td>
                    <td style={{
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      maxWidth: '100px'
                    }}>{item.youtube}</td>
                    <td style={{
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      maxWidth: '100px'
                    }}>{item.instagram}</td>
                    <td style={{
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      maxWidth: '100px'
                    }}>{item.twitter}</td>
                    <td style={{
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      maxWidth: '100px'
                    }}>{item.approvalMakerStatus.statusName}</td>

                    <td>
                      <div
                          onClick={() => {
                            setEditData(item);
                            setShowCreate(true);
                          }}
                          style={{color: 'red', fontWeight: 'bold', cursor: 'pointer'}}
                      >
                        수정
                      </div>
                    </td>
                  </tr>
              );
            })}
            </tbody>
          </table>
        </article>

        <Pagination
            currentPage={currentPage}
            totalElements={data && data.totalElements}
            listCount={listCount}
            setCurrentPage={setCurrentPage}
        />
        {showCreate && (
            <ModalPortal>
              <MakerUpdateModal isShowModal={showCreate} setIsShowModal={setShowCreate} editData={editData} />
            </ModalPortal>
        )}
      </section>
  );
};

export default Maker;

import ReactDOM from 'react-dom';

import styles from './modalPortal.module.scss';

const ModalPortal = ({ children }: any) => {
  return ReactDOM.createPortal(
    <section className={styles.modalPortalWrap}>{children}</section>,
    document.getElementById('modal') as HTMLElement
  );
};

export default ModalPortal;

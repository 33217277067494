import { Contract } from 'web3-eth-contract';

import { contractName, getAccount, getChainId, getContract } from './contract';
import { findIds } from './nftContract';
import { IMintParams } from '../types/Nft';

// land mint
export const landMintApi = async (data: IMintParams) => {
  const chainId = await getChainId();
  const account = await getAccount();
  try {
    const res = await (getContract(chainId, contractName.LandNFT) as Contract).methods
      .mint(data.island, data.usage, data.scale, data.form, data.amount, data.makerAddress)
      .send({
        from: account,
      });
    if (res) {
      const idsResult = findIds('TransferSingle', res);
      return idsResult;
    }
    return null;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

import Web3 from 'web3';
import { AbiItem } from 'web3-utils';

import RoleManagerTestNet from '../contracts/testnet/roleManager.json';
import NFTBaseTestNet from '../contracts/testnet/nftBase.json';
import LandNFTTestNet from '../contracts/testnet/landNFT.json';
import OpenSaleTestNet from '../contracts/testnet/opensale.json';
import BuildingNFTTestNet from '../contracts/testnet/buildingNFT.json';

import FeedABI from '../contracts/mainnet/feedABI.json';
import RoleManagerMainNet from '../contracts/mainnet/roleManager.json';
import NFTBaseMainNet from '../contracts/mainnet/nftBase.json';
import OpenSaleMainNet from '../contracts/mainnet/opensale.json';
import LandNFTMainNet from '../contracts/mainnet/landNFT.json';
import BuildingNFTMainNet from '../contracts/mainnet/buildingNFT.json';

// 테스트넷 컨트랙트
export const TestContractAddress = {
  RoleManager: '0x808C5cc45F8F9f38AF49B903f7bc2A85b2114084',
  NFTBase: '0x62c51F9C41dde2D34CBb48e5dFda09636Ff604A7',
  LandNFT: '0x68Bebc490ebe8783B2990bFb1AA7501D8EFFCdC9',
  OpenSale: '0x18595D79dCeE413Fc19E776441eB5d3c7AE1aBAf',
  BuildingNFT: '0xDBfF75ddBb37e1e8a90Fab8066fd77C9b34dE4f3',
  Feed: '0x57c636090Bc399e88A3605D05e3857Dd706B87A6',
} as const;
type TestContractAddress = (typeof TestContractAddress)[keyof typeof TestContractAddress];

// 메인넷 컨트랙트
export const MainContractAddress = {
  RoleManager: '0xc3ABE770779F8c61fFaa75527167a49A114eeE62',
  NFTBase: '0x458A9887667B731A599179FC1f428E474B40BB66',
  LandNFT: '0x279959CC621EFD1acFd57658ec5220ac38f24B4D',
  OpenSale: '0xc89236354a82fdEe1ba84F3c5E0333dC4c26BB47',
  BuildingNFT: '0xeb165Bae078D2D29A045bbCa645Cd4A3222888A9',
  Matching: '0xA8B17d9cb698d0b218e376C6EAa65D2fF7edA2aD',
  Feed: '0x72c90D710550A80eb93b25F091032e791542709E',
} as const;

type MainContractAddress = (typeof MainContractAddress)[keyof typeof MainContractAddress];

export const contractName = {
  RoleManager: 'RoleManager',
  NFTBase: 'NFTBase',
  LandNFT: 'LandNFT',
  FeedNFT: 'FeedNFT',
  BuildingNFT: 'BuildingNFT',
  ExteriorNFT: 'ExteriorNFT',
  InteriorNFT: 'InteriorNFT',
  LandScapeNFT: 'LandScapeNFT',
  OpenSale: 'OpenSale',
} as const;
type contractName = (typeof TestContractAddress)[keyof typeof TestContractAddress];

// 컨트랙트 호출 함수
export const getContract = (chainId: number, name: string) => {
  let contract;
  const web3 = new Web3((window as any).ethereum);
  try {
    switch (name) {
      case contractName.RoleManager:
        contract = new web3.eth.Contract(
          chainId === 1 ? (RoleManagerMainNet as unknown as AbiItem) : (RoleManagerTestNet as unknown as AbiItem),
          chainId === 1 ? MainContractAddress.RoleManager : TestContractAddress.RoleManager
        );
        break;
      case contractName.NFTBase:
        contract = new web3.eth.Contract(
          chainId === 1 ? (NFTBaseMainNet as unknown as AbiItem) : (NFTBaseTestNet as unknown as AbiItem),
          chainId === 1 ? MainContractAddress.NFTBase : TestContractAddress.NFTBase
        );
        break;
      case contractName.FeedNFT:
        contract = new web3.eth.Contract(
          FeedABI as unknown as AbiItem,
          chainId === 42161 ? MainContractAddress.Feed : TestContractAddress.Feed
        );
        break;
      case contractName.LandNFT:
        contract = new web3.eth.Contract(
          chainId === 1 ? (LandNFTMainNet as unknown as AbiItem) : (LandNFTTestNet as unknown as AbiItem),
          chainId === 1 ? MainContractAddress.LandNFT : TestContractAddress.LandNFT
        );
        break;
      case contractName.BuildingNFT:
        contract = new web3.eth.Contract(
          chainId === 1 ? (BuildingNFTMainNet as unknown as AbiItem) : (BuildingNFTTestNet as unknown as AbiItem),
          chainId === 1 ? MainContractAddress.BuildingNFT : TestContractAddress.BuildingNFT
        );
        break;
      case contractName.OpenSale:
        contract = new web3.eth.Contract(
          chainId === 1 ? (OpenSaleMainNet as unknown as AbiItem) : (OpenSaleTestNet as unknown as AbiItem),
          chainId === 1 ? MainContractAddress.OpenSale : TestContractAddress.OpenSale
        );
        break;

      default:
        contract = contractName;
    }
  } catch (error: any) {
    throw new Error(error.message);
  }
  return contract;
};

// account 가져오기
export const getAccount = async () => {
  try {
    const web3 = new Web3((window as any).ethereum);
    const account = await web3.eth.getAccounts();
    return account[0];
  } catch (error: any) {
    throw new Error(error.message);
  }
};

// chain Id 가져오기
export const getChainId = async () => {
  try {
    const web3 = new Web3((window as any).ethereum);
    const chainId = await web3.eth.getChainId();

    return chainId;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

import NftButton from '../Button/NftButton/NftButton';

import styles from './buttonList.module.scss';

interface IProps {
  id: string;
  data: string[];
  active: string;
  keyName: string;
  setClick: (value: string, keyName: string) => void;
  styleName?: string;
}
const ButtonList = ({ id, data, active, keyName, setClick, styleName }: IProps) => {
  return (
    <ul className={`${styles.listWrap} ${styleName && `${styles.usageWrap}`}`}>
      {data.map((item) => {
        return (
          <li key={`${id}_${Math.random() * 1000}`}>
            <NftButton
              text={item}
              styleName={item === `${active}` ? 'clickActive' : null}
              keyName={keyName}
              clickBtn={setClick}
            />
          </li>
        );
      })}
    </ul>
  );
};

export default ButtonList;

/* eslint-disable no-alert */
import { useEffect, useState } from 'react';

import { getFeedBuildingList } from '../../services/axiosPrivate';

import Pagination from '../../components/Common/Pagination/Pagination';

import styles from './feed.module.scss';
import Button from '../../components/Common/Button/Button';
import ModalPortal from '../../components/Modal/ModalPortal';
import FeedCreateModal from '../../components/Modal/FeedCreateModal/FeedCreateModal';
import { getChainId, getAccount } from '../../services/contract';

const Feed = () => {
  const [data, setData] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const listCount = 20;
  const [showCreate, setShowCreate] = useState(false);

  const getUser = async () => {
    const res = await getFeedBuildingList({ page: currentPage, listCount });
    setData(res);
  };

  useEffect(() => {
    getUser();
  }, [currentPage]);

  const handleOpenExcelUploadModal = async () => {
    const chainId = await getChainId();
    const account = await getAccount();
    if (!account) {
      alert('계정을 연결해주세요');
      return;
    }
    if (chainId !== 42161 && chainId !== 421614) {
      alert('아비트럼에 연결하세요.');
      return;
    }
    setShowCreate((prev) => !prev);
  };

  return (
    <section className={styles.userWrap}>
      <h2>Feed</h2>

      <div style={{ width: '150px', marginBottom: '10px' }}>
        <Button text='피드빌딩등록' styleName='uploadBtn' clickBtn={handleOpenExcelUploadModal} />
      </div>
      <article className={styles.tableWrap}>
        <table>
          <thead>
            <tr>
              <th>id</th>
              <th>빌딩이름</th>
              <th>피드타입</th>
              <th>피드썸네일</th>
              <th>피드이미지</th>
              <th>피드이름</th>
              {/* <th>피드내용</th> */}
              <th>활성화</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item: any) => {
              return (
                <tr key={`${item.id}_${Math.random() * 1000}`}>
                  <td>{item.id}</td>
                  <td>{item.buildingNftName}</td>
                  <td>{item.feedType}</td>

                  <td>
                    <img alt='thumb' style={{ width: '50px' }} src={item.feedThumbnail} />
                  </td>
                  <td>
                    {' '}
                    <img alt='feedImg' style={{ width: '100px' }} src={item.feedImage} />
                  </td>
                  <td>{item.feedName}</td>
                  <td>{item.enabled ? '활성' : '비활성'}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </article>

      <Pagination
        currentPage={currentPage}
        totalElements={data && data.totalElements}
        listCount={listCount}
        setCurrentPage={setCurrentPage}
      />
      {showCreate && (
        <ModalPortal>
          <FeedCreateModal isShowModal={showCreate} setIsShowModal={setShowCreate} />
        </ModalPortal>
      )}
    </section>
  );
};

export default Feed;

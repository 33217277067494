import { Route, Routes } from 'react-router-dom';
import { Web3ReactProvider } from '@web3-react/core';
import { ExternalProvider, JsonRpcFetchFunc, Web3Provider } from '@ethersproject/providers';

import PrivateRoute from './PrivateRoute';
import Layout from '../components/Layout/Layout';
import Login from '../pages/Login/Login';
import NFT from '../pages/NFT/NFT';
// import User from '../pages/User/User';
// import Admin from '../pages/Admin/Admin';
import NotFound from '../pages/NotFound/NotFound';
import OpenSale from '../pages/OpenSale/OpenSale';
import Home from '../pages/Home/Home';
import Feed from '../pages/Feed/Feed';
import Drops from '../pages/Drops/Drops';
import Banner from '../pages/Banner/Banner';
import Maker from '../pages/Maker/Maker';
import Project from '../pages/Project/Project';

const RootRoute = () => {
  const getLibrary = (provider: ExternalProvider | JsonRpcFetchFunc) => new Web3Provider(provider);

  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <Routes>
        <Route path='/' element={<PrivateRoute />}>
          <Route path='/' element={<Layout />}>
            <Route path='/' element={<Home />} />
            {/* <Route path='/user' element={<User />} /> */}
            <Route path='nft' element={<NFT />} />
            <Route path='feed' element={<Feed />} />
            <Route path='drop' element={<Drops />} />
            <Route path='banner' element={<Banner />} />
            <Route path='maker' element={<Maker />} />
            <Route path='project' element={<Project />} />
            {/* <Route path='admin' element={<Admin />} /> */}
            {/* <Route path='openSale' element={<OpenSale />} /> */}
          </Route>
        </Route>

        <Route path='login' element={<Login />} />
        <Route path='/*' element={<NotFound />} />
      </Routes>
    </Web3ReactProvider>
  );
};

export default RootRoute;

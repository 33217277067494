import axios from './instance';
import { IAdminAccount, IUserParams } from '../types/User';
import { INftListParams, IUploadParams } from '../types/Nft';
import { IOpenSaleList } from '../types/OpenSale';

const BASE_URL = process.env.REACT_APP_BASEURL;

// user list
export const getUserApi = async (params: IUserParams) => {
  try {
    const res = await axios.get(`${BASE_URL}/users/user/userinfo`, {
      params: {
        page: params.page,
        listCount: params.listCount,
      },
    });
    return res.data.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

// user authenticate
export const createBuildingNft = async (params: FormData) => {
  try {
    const r = await axios.post(`${BASE_URL}/building-feed-nfts`, params, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return r.data.data;
  } catch (error: any) {
    console.log(error);
    throw new Error(error.message);
  }
};

// user list
export const getDropList = async (params: IUserParams) => {
  try {
    const res = await axios.get(`${BASE_URL}/banner-drops?page=${params.page}&limit=${params.listCount}`);

    return res.data.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

// user list
export const createDrop = async (params: FormData) => {
  try {
    const res = await axios.post(`${BASE_URL}/banner-drops`, params, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return res.data.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

// user list
export const getBanner = async (params: IUserParams) => {
  try {
    const res = await axios.get(`${BASE_URL}/banner-drop-infos?page=${params.page}&limit=${params.listCount}`);

    return res.data.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

// 메이커 목록 조회
export const getMakers = async (params: any) => {
  try {
    const { page, limit, status } = params;
    const res = await axios.get(`${BASE_URL}/v2/makers?page=${page}&limit=${limit}&status=${status}`);

    return res.data.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

// project 목록 조회
export const getProjects = async (params: any) => {
  try {
    const { page, limit, status } = params;
    console.log('status', status);
    const { data } = await axios.get(`${BASE_URL}/v2/projects?page=${page}&limit=${limit}&status=${status}`);

    return data.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

// user list
export const createBanner = async (params: FormData) => {
  try {
    const res = await axios.post(`${BASE_URL}/banner-drop-infos`, params, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return res.data.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

// user list
export const editBanner = async (params: FormData, seq: string) => {
  try {
    const res = await axios.put(`${BASE_URL}/banner-drop-infos/${seq}`, params, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return res.data.data;
  } catch (error: any) {
    console.log(error);
    throw new Error(error.message);
  }
};

// user list
export const editDrops = async (params: FormData, seq: string) => {
  try {
    const res = await axios.put(`${BASE_URL}/banner-drops/${seq}`, params, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return res.data.data;
  } catch (error: any) {
    console.log(error);
    throw new Error(error.message);
  }
};

// user list
export const getFeedBuildingList = async (params: IUserParams) => {
  try {
    const res = await axios.get(`${BASE_URL}/building-feed-nfts?page=${params.page}&limit=${params.listCount}`);
    return res.data.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

// admin list
export const getAdminListApi = async (params: IUserParams) => {
  try {
    const res = await axios.get(`${BASE_URL}/admin/list`, {
      params: {
        page: params.page,
        listCount: params.listCount,
      },
    });
    return res.data.data.content;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

// admin id 중복확인
export const checkIdApi = async (value: string) => {
  try {
    const res = await axios.get(`${BASE_URL}/admin/idCheck`, {
      params: {
        id: value,
      },
    });
    return res.data.data.idUsed;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

// admin id 생성
export const createAdminAccountApi = async (params: IAdminAccount) => {
  try {
    const res = await axios.post(`${BASE_URL}/admin/create`, {
      id: params.id,
      password: params.password,
      grade: params.grade,
    });

    return res.data.message;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const updateMaker = async (seq: number, params: FormData) => {
    try {
      const { data } = await axios.put(`${BASE_URL}/v2/makers/${seq}`, params, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
        return data.data;
    } catch (error: any) {
        throw new Error(error.message);
    }
}

export const updateProject = async (id: number, params: FormData) => {
  try {
    const { data } = await axios.put(`${BASE_URL}/v2/projects/${id}`, params, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return data.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
}

export const getProjectTypes = async () => {
    try {
        const { data } = await axios.get(`${BASE_URL}/v2/project-types?page=0&limit=100`);
        return data.data;
    } catch (error: any) {
        throw new Error(error.message);
    }
}

// admin id 수정
export const updateAdminAccountApi = async (value: string, params: IAdminAccount) => {
  try {
    const res = await axios.patch(`${BASE_URL}/admin/update?id=${value}`, {
      id: params.id,
      password: params.password,
      grade: params.grade,
    });
    return res.data.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

// admin id 삭제
export const deleteAdminAccountApi = async (value: string) => {
  try {
    const res = await axios.delete(`${BASE_URL}/admin/delete`, {
      params: {
        id: value,
      },
    });
    return res.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

// s3 upload
export const s3UploadApi = async (params: IUploadParams) => {
  try {
    const res = await axios.post(`${BASE_URL}/nft/upload`, params, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return res.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

// whitelist
export const whiteListApi = async (params: any) => {
  try {
    const res = await axios.get(`${BASE_URL}/whitelist/list`, {
      params: { page: params.page, listCount: params.listCount },
    });
    return res.data.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

// nft id check
export const nftIdCheck = async (params: any) => {
  try {
    const res = await axios.post(`${BASE_URL}/nft/check`, {
      id: params.id,
      address: params.address,
    });
    return res.data.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

// nft list
export const getNftListApi = async (params: INftListParams) => {
  try {
    const res = await axios.get(`${BASE_URL}/nft/nftList`, {
      params: {
        page: params.page,
        listCount: params.listCount,
        orderDirection: params.orderDirection,
        category: params.category,
        s3: params.s3,
      },
    });

    return res.data.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

// openSaleList
export const getOpenListApi = async (params: IOpenSaleList) => {
  try {
    const res = await axios.get(`${BASE_URL}/openSaleList`, {
      params: {
        page: params.page,
        listCount: params.listCount,
        orderDirection: params.orderDirection,
        round: params.round,
        salesStatus: params.salesStatus,
        s3: params.s3,
      },
    });

    return res.data.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

// Round List
export const getRoundListApi = async (params: any) => {
  try {
    const res = await axios.get(`${BASE_URL}/roundList`, {
      params: {
        offset: params.offset,
        limit: params.limit,
        orderDirection: params.orderDirection,
        id: params.id,
        isPrivate: params.isPrivate,
      },
    });

    return res.data.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

// nft totalCount
export const getNFTTotalCountApi = async (params: any) => {
  try {
    const res = await axios.get(`${BASE_URL}/nft/totalCount`, {
      params: {
        category: params.category,
      },
    });

    return res.data.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

// openSale totalCount
export const getOpenSaleTotalCountApi = async (params: any) => {
  try {
    const res = await axios.get(`${BASE_URL}/opensaleTotalCount`, {
      params: {
        round: params.round,
        salesStatus: params.salesStatus,
      },
    });

    return res.data.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

// role list
export const getRoleListApi = async () => {
  try {
    const res = await axios.get(`${BASE_URL}/roleList`);

    return res.data.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

// 오픈 세일 총 판매금액
export const getOpenSaleTotalAmountApi = async () => {
  try {
    const res = await axios.get(`${BASE_URL}/nft/totalSale`);
    return res.data.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

// 총 가스비
export const getTotalGasPriceApi = async (address: string) => {
  try {
    const res = await axios.get(`${BASE_URL}/nft/totalGasPrice`, {
      params: {
        address,
      },
    });
    return res.data.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

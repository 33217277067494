import axios from 'axios';
import { updateAccessTockenApi } from './axiosPublic';

const instance = axios.create({
  baseURL:
    process.env.REACT_APP_MODE === 'production' ? 'https://api.admin.stelsi.org' : 'https://dev-api.admin.stelsi.org',
});

let subscribers: any[] = [];

const addSubscriber = (callback: any) => {
  subscribers.push(callback);
};

function signOut() {
  localStorage.removeItem('token');
  localStorage.removeItem('userInfo');
  localStorage.removeItem('wallet');
  window.location.href = '/login';
}

function onAccessTokenFetched(accessToken: any) {
  subscribers.forEach((callback) => callback(accessToken));
  subscribers = [];
}

const resetTokenAndReattemptRequest = async (error: any) => {
  let isAlreadyFetchingAccessToken = false;

  try {
    const { response: errorResponse } = error;

    const retryOriginalRequest = new Promise((resolve, reject) => {
      addSubscriber(async (accessToken: any) => {
        try {
          errorResponse.config.headers.authorization = `Bearer ${accessToken}`;
          resolve(instance(errorResponse.config));
        } catch (errors: any) {
          reject(errors);
        }
      });
    });

    if (!isAlreadyFetchingAccessToken) {
      isAlreadyFetchingAccessToken = true;

      const data = await updateAccessTockenApi();
      const localTocken = localStorage.getItem('token') && localStorage.getItem('token');
      const tockens = localTocken && JSON.parse(localTocken);

      localStorage.setItem('token', JSON.stringify({ accessToken: data, refreshToken: tockens.refreshToken }));
      isAlreadyFetchingAccessToken = false;
      onAccessTokenFetched(data);
    }

    return retryOriginalRequest;
  } catch (err: any) {
    signOut();
    return Promise.reject(err);
  }
};

instance.interceptors.request.use(
  (request) => {
    const localTocken = localStorage.getItem('token');
    const tokens = localTocken && JSON.parse(localTocken);

    if (tokens?.accessToken) {
      request.headers = {
        ...request.headers,
        authorization: `Bearer ${tokens?.accessToken}`,
      };
    }

    return request;
  },
  (error) => Promise.reject(error)
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },

  async (error) => {
    const { response: errorResponse } = error;

    if (errorResponse?.status === 401) {
      const res = await resetTokenAndReattemptRequest(error);
      return res;
    }
    return Promise.reject(error);
  }
);

export default instance;

import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import { connectWalletState } from '../../recoil/recoil';
import { TAB_LIST } from '../../utils';

import NFTList from './NFTList/NFTList';
import LandNFT from './LandNFT/LandNFT';
import Button from '../../components/Common/Button/Button';
import BatchMintModal from '../../components/Modal/BatchMintModal/BatchMintModal';
import ModalPortal from '../../components/Modal/ModalPortal';
import ResourceUploadModal from '../../components/Modal/ResourceUploadModal/ResourceUploadModal';

import '../../styles/react-tabs.scss';
import styles from './nft.module.scss';
import BuildingNFT from './BuildingNFT/BuildingNFT';

const NFT = () => {
  const [isShowExcelModal, setIsShowExcelModal] = useState(false);
  const [isShowResourceModal, setIsShowResourceModal] = useState(false);
  const isConnectWallet = useRecoilValue(connectWalletState);

  const handleOpenExcelUploadModal = () => {
    setIsShowExcelModal((prev) => !prev);
  };

  const handleOpenResourceUploadModal = () => {
    setIsShowResourceModal((prev) => !prev);
  };

  return (
    <section className={styles.nftWrap}>
      {isConnectWallet ? (
        <>
          <div className={styles.titleWrap}>
            <h2>NFT</h2>
            <div>
              <Button text='대량 업로드' styleName='uploadBtn' clickBtn={handleOpenExcelUploadModal} />
              <Button text='리소스 대량 업로드' styleName='uploadBtn' clickBtn={handleOpenResourceUploadModal} />
            </div>
          </div>

          <Tabs>
            <TabList>
              {TAB_LIST.map((item) => {
                return <Tab key={Math.random() * 1000}>{item}</Tab>;
              })}
            </TabList>

            <TabPanel>
              <article>
                <NFTList />
              </article>
            </TabPanel>

            <TabPanel>
              <article>
                <LandNFT />
              </article>
            </TabPanel>

            <TabPanel>
              <article>
                <BuildingNFT />
              </article>
            </TabPanel>
          </Tabs>
        </>
      ) : (
        <div className={styles.checkWallet}>지갑을 연동 해주세요</div>
      )}
      {isShowExcelModal && (
        <ModalPortal>
          <BatchMintModal isShowModal={isShowExcelModal} setIsShowModal={setIsShowExcelModal} />
        </ModalPortal>
      )}
      {isShowResourceModal && (
        <ModalPortal>
          <ResourceUploadModal isShowModal={isShowResourceModal} setIsShowModal={setIsShowResourceModal} />
        </ModalPortal>
      )}
    </section>
  );
};

export default NFT;

import Loading from '../../../assets/loading.gif';
import styles from './loadingModal.module.scss';

export const LoadingModal = () => {
  return (
    <div className={styles.loadingWrap}>
      <img src={Loading} alt='loading' />
    </div>
  );
};

import { useWeb3React } from '@web3-react/core';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import { subMenuState } from '../../../recoil/recoil';
import Button from '../Button/Button';

import styles from './subMenu.module.scss';

const SubMenu = () => {
  const [isOpenMenu, setIsOpenMenu] = useRecoilState(subMenuState);
  const navigate = useNavigate();
  const { deactivate, account } = useWeb3React();

  const handleLogout = () => {
    if (account) {
      deactivate();
      localStorage.removeItem('wallet');
    }

    setIsOpenMenu(false);
    localStorage.removeItem('token');
    localStorage.removeItem('userInfo');
    navigate('/login');
  };

  return (
    <ul className={`${styles.subMenuWrap} ${isOpenMenu ? `${styles.active}` : ''}`}>
      <li>
        <Button clickBtn={handleLogout} styleName={null} text='로그아웃' />
      </li>
    </ul>
  );
};

export default SubMenu;

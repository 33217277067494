import { useEffect, useState, useRef } from 'react';
import {
  getTotalGasPriceApi,
  getOpenSaleTotalAmountApi,
  getRoleListApi,
  getNFTTotalCountApi,
  getOpenSaleTotalCountApi,
} from '../../services/axiosPrivate';
import styles from './home.module.scss';

const Home = () => {
  const [nftTotalCount, setNftTotalCount] = useState('');
  const [openSaleTotalCount, setOpenSaleTotalCount] = useState('');
  const [openSaleTotalAmount, setOpenSaleTotalAmount] = useState('');
  const [gasPrice, setGasPrice] = useState('');
  const isRequest = useRef(true);

  const getNftTotalCount = async () => {
    const res = await getNFTTotalCountApi('ALL');
    setNftTotalCount(res);
  };

  const getOpenSaleTotalCount = async () => {
    const totalCount: any = {
      round: 'ALL',
      salesStatus: 'ALL',
    };
    delete totalCount.round;
    delete totalCount.salesStatus;

    const res = await getOpenSaleTotalCountApi(totalCount);
    setOpenSaleTotalCount(res);
  };

  const getOpenSaleTotalAmount = async () => {
    const res = await getOpenSaleTotalAmountApi();
    setOpenSaleTotalAmount(res);
  };

  const getTotalGasPrice = async () => {
    await getRoleListApi().then(async (response: any) => {
      const contractAddress = response.map((item: any) => item.id);
      const contractAddressStr = contractAddress.join(',');

      const res = await getTotalGasPriceApi(contractAddressStr);
      setGasPrice(res);
    });
  };

  useEffect(() => {
    if (isRequest.current) {
      getNftTotalCount();
      // getOpenSaleTotalCount();
      // getOpenSaleTotalAmount();
      // getTotalGasPrice();
    }
    return () => {
      isRequest.current = false;
    };
  }, []);

  return (
    <section className={styles.dashBoardWrap}>
      <article className={styles.ss}>
        <ul>
          <li>
            <dl>
              <dt>총 NFT </dt>
              <dd>{nftTotalCount}</dd>
            </dl>
          </li>
          <li>
            <dl>
              <dt>총 Open Sale</dt>
              <dd>{openSaleTotalCount}</dd>
            </dl>
          </li>
          <li>
            <dl>
              <dt>오픈세일 총 판매금액</dt>
              <dd>{openSaleTotalAmount} ETH</dd>
            </dl>
          </li>
          <li>
            <dl>
              <dt>총 가스비</dt>
              <dd>{gasPrice} ETH</dd>
            </dl>
          </li>
        </ul>
      </article>
    </section>
  );
};
export default Home;

import styles from './formLayout.module.scss';

interface IProps {
  id: string;
  label: string;
  children: any;
  errorMsg?: string;
}

const FormLayout = ({ id, label, children, errorMsg }: IProps) => {
  return (
    <div className={styles.formGroup}>
      <label htmlFor={id} className={styles.label}>
        {label}
      </label>
      {children}
      {errorMsg && <span>{errorMsg}</span>}
    </div>
  );
};

export default FormLayout;

import { useEffect, useState } from 'react';
import {getProjectTypes, getProjects} from '../../services/axiosPrivate';
import Pagination from '../../components/Common/Pagination/Pagination';
import ModalPortal from '../../components/Modal/ModalPortal';
import ProjectUpdateModal from '../../components/Modal/ProjectUpdateModal/ProjectUpdateModal';
import styles from './project.module.scss';
import Select from '../../components/Common/Select/Select';

interface ColumnDefinition {
  key: string;
  header: string;
  width?: string;
  type?: 'text' | 'image' | 'button';
  style?: React.CSSProperties;
}

interface TableProps {
  columns: ColumnDefinition[];
  data: any[];
  onEdit: (item: any) => void;
}

const TableHeader: React.FC<{ columns: ColumnDefinition[] }> = ({ columns }) => (
    <thead>
    <tr>
      {columns.map((column) => (
          <th key={column.key} style={{ width: column.width }}>
            {column.header}
          </th>
      ))}
    </tr>
    </thead>
);

const TableCell: React.FC<any> = ({ column, value, onClick }) => {
  const renderObjectValue = (obj: Record<string, any>): string => {
    switch (column.key) {
      case 'type':
        return obj.name?.toString() || '';
      case 'maker':
        return obj.nickname?.toString() || '';
      default:
        return JSON.stringify(obj);
    }
  };

  const renderValue = (): string => {
    if (value === null || value === undefined) {
      return '';
    }
    if (typeof value === 'object') {
      return renderObjectValue(value);
    }
    return value.toString();
  };

  switch (column.type) {
    case 'image':
      return (
          <td style={{ width: column.width }}>
            <img src={value} alt={column.header} style={{ width: '50px', height: '50px', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: '150px', minWidth: '75px' }} />
          </td>
      );
    case 'button':
      return (
          <td style={{ color: 'red', fontWeight: 'bold', cursor: 'pointer', width: column.width }}>
            <button onClick={onClick}>{value}</button>
          </td>
      );
    default:
      return <td style={{ width: column.width, maxWidth: '100px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{renderValue()}</td>;
  }
};

const TableRow: React.FC<{ item: any; columns: ColumnDefinition[]; onEdit: (item: any) => void }> = ({ item, columns, onEdit }) => {
  return (
      <tr>
        {columns.map((column) => (
            <TableCell
                key={column.key}
                column={column}
                value={column.type === 'button' ? '수정' : item[column.key]}
                onClick={column.type === 'button' ? () => onEdit(item) : undefined}
            />
        ))}
      </tr>
  )
};

const Table: React.FC<TableProps> = ({ columns, data, onEdit }) => (
    <table>
      <TableHeader columns={columns} />
      <tbody>
      {data.map((item) => (
              <TableRow
                  key={`${item.id}_${Math.random() * 1000}`}
                  item={item}
                  columns={columns}
                  onEdit={onEdit}
              />
          )
      )}
      </tbody>
    </table>
);

const Project = () => {
  const [data, setData] = useState<any>([]);
  const [projectTypes, setProjectTypes] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const listCount = 20;
  const [showUpdate, setShowUpdate] = useState(false);
  const [editData, setEditData] = useState(null);
  const [status, setStatus] = useState<any>('all');
  const columns: ColumnDefinition[] = [
    { key: 'id', header: 'ID', width: '80px' },
    { key: 'name', header: '이름', width: '150px' },
    { key: 'description', header: '설명', width: '200px' },
    { key: 'firstFloor', header: '지상', width: '100px' },
    { key: 'basement', header: '지하', width: '100px' },
    { key: 'status', header: '상태', width: '100px' },
    { key: 'year', header: '연도', width: '80px' },
    { key: 'location', header: '위치', width: '150px' },
    { key: 'size', header: '크기', width: '100px' },
    {
      key: 'perspectiveImage',
      header: '조감도',
      width: '100px',
      type: 'image'
    },
    {
      key: 'siteplanImage',
      header: '배치도',
      width: '100px',
      type: 'image'
    },
    {
      key: 'typicalFloorPlanImage',
      header: '평면도',
      width: '100px',
      type: 'image'
    },
    {
      key: 'elevationImage',
      header: '입면도',
      width: '100px',
      type: 'image'
    },
    {
      key: 'sideElevationImage',
      header: '측면도',
      width: '100px',
      type: 'image'
    },
    {
      key: 'lSectionImage',
      header: 'L단면도',
      width: '100px',
      type: 'image'
    },
    {
      key: 'sSectionImage',
      header: 'S단면도',
      width: '100px',
      type: 'image'
    },
    { key: 'maker', header: '제작자', width: '120px' },
    { key: 'type', header: '타입', width: '100px' },
    { key: 'createdAt', header: '생성일', width: '150px' },
    {
      key: 'edit',
      header: '수정',
      width: '80px',
      type: 'button'
    }
  ];

  const handleEdit = (item: any) => {
    setEditData(item);
    setShowUpdate(true);
  };

  const getProject = async (status?: string) => {
    const res = await getProjects({ page: currentPage, limit: listCount, status });
    setData(res);
  };

  const getProejctType = async () => {
    const res = await getProjectTypes();
    setProjectTypes(
        res.map((data: any) => {
          return { value: data.id, label: data.name }
        })
    );
  }

  type ProjectStatusType = {
    [key: number]: string;
  };
  const ProjectStatus: ProjectStatusType = {
    0: 'all',
    1: 'Built',
    2: 'Unbuilt',
  };
  const getStatusOptions = () => {
    return Object.entries(ProjectStatus).map(([key, value]) => ({ value: key, label: value }))
  }
  const handleChange = (e: any) => {
    const { value } = e.target;
    setStatus(value);
  };

  useEffect(() => {
    getProject(ProjectStatus[status] ?? 'all');
    getProejctType();
  }, [currentPage, status]);

  return (
      <section className={styles.userWrap}>
        <h2>Project</h2>
        <Select
            value={status}
            id='status'
            onChange={handleChange}
            options={getStatusOptions()}
        />
        <article className={styles.tableWrap}>
          <Table columns={columns} data={data} onEdit={handleEdit} />
        </article>

        <Pagination
            currentPage={currentPage}
            totalElements={data && data.totalElements}
            listCount={listCount}
            setCurrentPage={setCurrentPage}
        />

        {showUpdate && (
            <ModalPortal>
              <ProjectUpdateModal isShowModal={showUpdate} setIsShowModal={setShowUpdate} editData={editData} projectTypes={projectTypes} />
            </ModalPortal>
        )}
      </section>
  );
};

export default Project;

import { FormEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { Toaster } from 'react-hot-toast';

import Button from '../../components/Common/Button/Button';
import Input from '../../components/Common/Input/Input';
import FormLayout from '../../components/Common/FormLayout/FormLayout';
import useInput from '../../hooks/useInput';
import { accessState } from '../../recoil/recoil';
import { getLoginApi } from '../../services/axiosPublic';

import styles from './login.module.scss';
import jwtDecode from 'jwt-decode';

const Login = () => {
  const [errorMsg, setErrorMsg] = useState('');
  const [id, setId] = useInput('', setErrorMsg);
  const [password, setPassword] = useInput('', setErrorMsg);
  const setAccessState = useSetRecoilState(accessState);

  const navigate = useNavigate();

  const login = async (e: FormEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (password === '') {
      setErrorMsg('비밀번호를 입력해주세요');
    }

    if (id === '') {
      setErrorMsg('아이디를 입력해주세요');
    }

    if (id !== '' && password !== '') {
      try {
        const res = await getLoginApi({ id, password });
        if (res.data) {
          const { sub, grade }: any = jwtDecode(res.data.accessToken);
          localStorage.setItem('token', JSON.stringify(res.data));
          localStorage.setItem('userInfo', JSON.stringify({ id: sub, grade }));
          setAccessState(res.data.accessToken);
          navigate('/');
        } else {
          setErrorMsg(res.data.message);
        }
      } catch (error: any) {
        setErrorMsg(error.message);
      }
    }
  };

  return (
    <section className={styles.loginWrap}>
      <h2>로그인</h2>

      <form>
        <FormLayout id='id' label='아이디'>
          <Input type='text' id='id' value={id} changeValue={setId} />
        </FormLayout>

        <FormLayout id='password' label='비밀번호'>
          <Input type='password' id='password' value={password} changeValue={setPassword} />
        </FormLayout>

        <p className={styles.errorMsg}>{errorMsg}</p>

        <Button text='로그인' isSubmit styleName='loginBtn' clickBtn={login} />
      </form>

      <Toaster />
    </section>
  );
};

export default Login;

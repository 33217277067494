import { NavLink } from 'react-router-dom';
import cx from 'classnames';

import styles from './aside.module.scss';

const Aside = () => {
  return (
    <aside className={styles.aside}>
      <nav className={styles.nav}>
        {/* <NavLink to='/user' className={({ isActive }) => cx({ [styles.isActive]: isActive })}>
          <span className='material-symbols-outlined'>person</span>
          User
        </NavLink> */}
        <NavLink to='/nft' className={({ isActive }) => cx({ [styles.isActive]: isActive })}>
          <span className='material-symbols-outlined'>atr</span>NFT
        </NavLink>
        <NavLink to='/feed' className={({ isActive }) => cx({ [styles.isActive]: isActive })}>
          <span className='material-symbols-outlined'>atr</span>FEED
        </NavLink>
        <NavLink to='/drop' className={({ isActive }) => cx({ [styles.isActive]: isActive })}>
          <span className='material-symbols-outlined'>atr</span>Drops
        </NavLink>
        <NavLink to='/banner' className={({ isActive }) => cx({ [styles.isActive]: isActive })}>
          <span className='material-symbols-outlined'>atr</span>홈화면 배너
        </NavLink>
        <NavLink to='/maker' className={({ isActive }) => cx({ [styles.isActive]: isActive })}>
          <span className='material-symbols-outlined'>atr</span>메이커
        </NavLink>
        <NavLink to='/project' className={({ isActive }) => cx({ [styles.isActive]: isActive })}>
          <span className='material-symbols-outlined'>atr</span>프로젝트
        </NavLink>
        {/* <NavLink to='/admin' className={({ isActive }) => cx({ [styles.isActive]: isActive })}>
          <span className='material-symbols-outlined'>settings</span>admin
        </NavLink> */}
        {/* <NavLink to='/opensale' className={({ isActive }) => cx({ [styles.isActive]: isActive })}>
          <span className='material-symbols-outlined'>atr</span>open sale
        </NavLink> */}
      </nav>
    </aside>
  );
};

export default Aside;

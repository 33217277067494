import Web3 from 'web3';
import { Contract } from 'web3-eth-contract';
import { contractName, getChainId, getContract } from './contract';

const web3 = new Web3((window as any).ethereum);

// 민터 권한 체크
export const getHasRole = async () => {
  const chainId = await getChainId();
  const account = await web3.eth.getAccounts();
  const role = web3.utils.keccak256('MINTER_ROLE');
  try {
    const res = await (getContract(chainId, contractName.RoleManager) as Contract).methods
      .hasRole(role, account[0])
      .call();
    return res;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

// 어드민 권한 체크
export const getAdminHasRole = async () => {
  const chainId = await getChainId();
  const account = await web3.eth.getAccounts();
  const role = web3.utils.keccak256('ADMIN_ROLE');

  try {
    const res = await (getContract(chainId, contractName.NFTBase) as Contract).methods.hasRole(role, account[0]).call();
    return res;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

import styles from './input.module.scss';

interface IProps {
  type: string;
  id: string;
  name?: string;
  value?: string[] | string | number;
  multiple?: boolean;
  accept?: string;
  changeValue: (e: any) => void;
}

const Input = ({ type, id,name, value, changeValue, multiple, accept }: IProps) => {
  return (
    <input
      type={type}
      id={id}
      name={name}
      value={value}
      onChange={changeValue}
      className={styles.input}
      multiple={type === 'file' && multiple}
      accept={accept}
    />
  );
};

export default Input;
